<template>
  <div>
    <list-item-selector
      :columns-assigned="columnsAssigned"
      :columns-available="columnsAvailable"
      :all-items="sortedAllItems"
      item-key="uniqueId"
      :assigned-items="sortedAssignedItems"
      :available-title="$t('availableActivities')"
      :assigned-title="$t('currentPatientActivities')"
      :show-expand="true"
      :data-loading="dataLoading"
      @update:assigned-selected="assignedSelectionChange"
      @update:assignedItems="assignedItemsChange"
      @available-item-expanded="itemExpanded"
      @assigned-item-expanded="itemExpanded"
    >
      <template #expanded-item="{ item }">
        <td class="pa-4" :colspan="columnsAssigned.length">
          <template v-if="item.type === 'protocol'">
            <div class="text--secondary">{{ $t('activitiesInProtocol') }}</div>
            <div v-for="activity in item.assignedProtocolActivities" :key="activity.id">
              {{ activity.name }}

              <!-- <div v-if="checkActivityAlreadyAssigned(activity, true)">
                <PatientActivityAssignAssistant
                  :patient-id="patientId"
                  :show="show"
                  :activity="activity"
                ></PatientActivityAssignAssistant>
              </div> -->
            </div>
          </template>
        </td>
      </template>
      <template #availableItem.data-table-expand="{ expand, item, isExpanded }">
        <div v-if="item.type === 'protocol'">
          <v-icon
            ref="iconItemExpanded"
            class="v-data-table__expand-icon"
            :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            @click="expand(!isExpanded)"
            >mdi-chevron-down</v-icon
          >
        </div>
      </template>

      <!-- <template #assignedItem.dialoguesTest="{ item }">
        <div v-if="checkActivityAlreadyAssigned(item, false)">
          <PatientActivityAssignAssistant
            :patient-id="patientId"
            :show="show"
            :item="item"
          ></PatientActivityAssignAssistant>
        </div>
      </template> -->

      <template #assignedItem.data-table-expand="{ expand, item, isExpanded }">
        <div v-if="item.type === 'protocol'">
          <v-icon
            ref="iconItemExpanded"
            class="v-data-table__expand-icon"
            :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            @click="expand(!isExpanded)"
            >mdi-chevron-down</v-icon
          >
        </div>
      </template>

      <template #assignedItem.startDate="{ item }">
        <v-edit-dialog :return-value.sync="item.startDate">
          <DateTimePickerField
            ref="startDate"
            v-model="item.startDate"
            :label="$t('startDate')"
            :max-date="item.endDate"
            picker-type="dateTime"
            single-line
            required
          ></DateTimePickerField>
        </v-edit-dialog>
      </template>
      <template #assignedItem.endDate="{ item }">
        <v-edit-dialog :return-value.sync="item.endDate">
          <DateTimePickerField
            v-model="item.endDate"
            :label="$t('endDate')"
            :min-date="item.startDate"
            single-line
            picker-type="dateTime"
          ></DateTimePickerField>
        </v-edit-dialog>
      </template>
    </list-item-selector>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import ListItemSelector from '@/components/ListItemsSelector.vue';
import DateTimePickerField from '@/components/DateTimePickerField.vue';

export default {
  name: 'PatientActivityAssign',

  components: {
    ListItemSelector,
    DateTimePickerField,
  },
  mixins: [translation, accessibility, virtuoseMixin],

  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    selectedCategory: {
      type: String,
      required: false,
      default: null,
    },
    allActivities: {
      type: Array,
      required: false,
      default: () => [],
    },
    allProtocols: {
      type: Array,
      required: false,
      default: () => [],
    },
    assignedActivities: {
      type: Array,
      default: () => [],
    },
    assignedProtocols: {
      type: Array,
      default: () => [],
    },
    assignedItemsChange: {
      type: Function,
      required: true,
    },
    loadProtocolActivities: {
      type: Function,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    dataLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedActivity: null,
      selectedProtocol: null,
    };
  },
  computed: {
    columnsAssigned: function () {
      return [
        {
          text: 'activitiesName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'activitiesDescription',
          value: 'description',
          filterable: true,
        },
        {
          text: 'startDate',
          value: 'startDate',
          filterable: true,
          width: '300px',
        },
        {
          text: 'endDate',
          value: 'endDate',
          width: '300px',
          filterable: true,
        },
        {
          text: 'protocolDetail',
          value: 'data-table-expand',
          filterable: false,
          sortable: false,
        },
        // {
        // value: 'dialoguesTest',
        // filterable: false,
        // sortable: false,
        // },
      ];
    },

    columnsAvailable: function () {
      return [
        {
          text: 'activitiesName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'activitiesDescription',
          value: 'description',
          filterable: true,
        },
        {
          text: 'protocolDetail',
          value: 'data-table-expand',
          filterable: false,
          sortable: false,
        },
        //     {
        //   value: 'dialoguesTest',
        //     filterable: false,
        // sortable: false,
        //},
      ];
    },

    allItems: function () {
      switch (this.selectedCategory) {
        case 'protocols':
          return this.allProtocols;

        case 'activities':
          return this.allActivities;

        case 'all':
          return [...this.allActivities, ...this.allProtocols];

        default:
          return [];
      }
    },

    assignedItems: function () {
      switch (this.selectedCategory) {
        case 'protocols':
          return this.assignedProtocols;

        case 'activities':
          return this.assignedActivities;

        case 'all':
          return [...this.assignedActivities, ...this.assignedProtocols];

        default:
          return [];
      }
    },
    sortedAssignedItems() {
      return this.assignedItems.slice().sort((oldDate, newDate) => {
        return new Date(newDate.startDate) - new Date(oldDate.startDate);
      });
    },
    sortedAllItems() {
      return this.allItems.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
  },

  methods: {
    assignedSelectionChange: function (selection) {
      if (selection?.length === 1) {
        this.selectedActivity = selection[0];
      } else {
        this.selectedActivity = null;
      }
    },

    async itemExpanded(data) {
      let item = data.item;
      if (data.value && item.type === 'protocol' && item.assignedProtocolActivities.length === 0) {
        this.loadProtocolActivities(item);
      }
    },

    // checkActivityAlreadyAssigned: function (activity, isProtocol) {
    //   if (isProtocol) {
    //     return (
    //       activity?.iotDeviceType?.code === 'vocalAssistant' &&
    //       this.assignedProtocols?.some(
    //         (assignedProtocol) =>
    //           assignedProtocol.patientId === this.patientId &&
    //           assignedProtocol?.assignedProtocolActivities?.some(
    //             (assignedProtocolActivity) => assignedProtocolActivity.id === activity.id
    //           )
    //       )
    //     );
    //   } else {
    //     return (
    //       activity.type === 'activity' &&
    //       activity?.iotDeviceType?.code === 'vocalAssistant' &&
    //       activity?.patientId === this.patientId
    //     );
    //   }
    // },
  },
};
</script>
