<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="1200px">
      <v-card color="white">
        <ModalTitle :title="title" />
        <WaitModal :show="showWaitModal" />
        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-row>
            <v-col cols="12" :md="dialogueId != null ? 6 : 12">
              <v-form ref="dialogueModalForm">
                <v-text-field
                  :id="getIdByName('dialogueName')"
                  ref="dialogueName"
                  v-model="formData.name"
                  :label="$t('dialogueName')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-text-field
                  :id="getIdByName('description')"
                  ref="description"
                  v-model="formData.description"
                  :label="$t('description')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />
              </v-form>
              <v-row>
                <!-- <v-col cols="auto">
                  <v-checkbox ref="checkActive" v-model="formData.active" :label="$t('active')" />
                </v-col> -->
                <v-col cols="auto">
                  <v-checkbox
                    ref="checkNormative"
                    v-model="formData.normative"
                    :label="$t('normative')"
                    :disabled="!$can(permissions.actions.manage, permissions.subjects.normative)"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="dialogueId != null" cols="12" md="6">
              <detail-field
                :id="getIdByName('createdDate')"
                ref="createdDate"
                v-model="formData.createdDate"
                :label="$t('createdDate')"
              />
              <detail-field
                :id="getIdByName('createdBy')"
                ref="createdBy"
                v-model="formData.createdBy"
                :label="$t('createdBy')"
              />
              <detail-field
                :id="getIdByName('modifiedDate')"
                ref="modifiedDate"
                v-model="formData.modifiedDate"
                :label="$t('modifiedDate')"
              />
              <detail-field
                :id="getIdByName('modifiedBy')"
                ref="modifiedBy"
                v-model="formData.modifiedBy"
                :label="$t('modifiedBy')"
              />
            </v-col>
          </v-row>
          <v-row v-if="formData.parameters.length" class="pt-0">
            <v-col class="pt-0">
              <v-tabs v-model="selectedTab" background-color="transparent">
                <v-tab>{{ $t('questionResponseTitle') }}</v-tab>
                <v-tab>
                  <div>{{ $t('translationTitle') }}</div>
                  <v-icon v-if="translationsMissing" class="ml-2" small>mdi-alert</v-icon>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row v-if="formData.parameters.length">
            <v-col>
              <v-tabs-items v-model="selectedTab" class="tabs">
                <v-tab-item>
                  <v-row>
                    <v-col>
                      <v-row dense>
                        <v-col>
                          <v-select
                            v-model="selectedLanguage"
                            :items="languages"
                            item-text="name"
                            item-value="code"
                            :label="$t('language')"
                            outlined
                            dense
                            hide-details
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="auto">
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn icon @click="toggleExpand()" v-on="on">
                                <v-icon>{{
                                  openList.length === 0 ? 'mdi-expand-all-outline' : 'mdi-collapse-all-outline'
                                }}</v-icon>
                              </v-btn>
                            </template>
                            {{ openList.length === 0 ? $t('expandAll') : $t('collapseAll') }}
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-treeview
                        ref="treeview"
                        :open="openList"
                        class="pt-2"
                        hoverable
                        dense
                        :items="formData.parameters"
                        activatable
                        return-object
                        :active.sync="activeItems"
                      >
                        <template #label="{ item }">
                          {{
                            item.type === 'question'
                              ? item.languages[item.languages.findIndex((l) => l.language === selectedLanguage)].value
                              : item.value === 'yes'
                              ? displayYes[selectedLanguage]
                              : displayNo[selectedLanguage]
                          }}
                        </template>
                        <template #prepend="{ item, active }">
                          <v-icon :color="active ? 'primary' : undefined">
                            {{ item.type === 'question' ? 'mdi-message-question' : 'mdi-message-reply-text-outline' }}
                          </v-icon>
                        </template>
                      </v-treeview>
                    </v-col>
                    <v-col v-if="!!activeItem" cols="auto">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col v-if="!!activeItem">
                      <v-form ref="formQuestionsResponses">
                        <template v-if="activeItem.type === 'question'">
                          <v-textarea
                            v-model="
                              activeItem.languages[
                                activeItem.languages.findIndex((l) => l.language === selectedLanguage)
                              ].value
                            "
                            rows="2"
                            auto-grow
                            filled
                            label="Question"
                            class="required-indicator"
                            :rules="[validationRules.required]"
                          ></v-textarea>
                          <v-btn ref="deleteQuestion" class="mt-4" @click="deleteQuestion">{{
                            $t('deleteQuestion')
                          }}</v-btn>
                        </template>
                        <template v-if="activeItem.type === 'response'">
                          <v-textarea
                            v-model="activeItem.detailResponse"
                            rows="2"
                            auto-grow
                            filled
                            :label="$t('detailedResponseSheet')"
                          ></v-textarea>
                          <v-select
                            v-model="activeItem.alertLevel"
                            filled
                            :items="alertLevels"
                            :label="$t('answerType')"
                            persistent-hint
                            :hint="$t('abnormalValueHint')"
                          ></v-select>
                          <v-checkbox
                            v-model="activeItem.endDialogueExecution"
                            :label="$t('endDialogueExecution')"
                            persistent-hint
                            :hint="$t('endDialogueExecutionHint')"
                          />
                          <v-btn ref="addQuestionAfter" class="mt-4" @click="addQuestion(activeItem)">{{
                            $t('addQuestionAfterResponse')
                          }}</v-btn>
                        </template>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row v-for="(item, index) in flatItems" :key="index">
                    <v-col v-for="language in languages" :key="language.code">
                      <v-textarea
                        v-model="item.languages[item.languages.findIndex((l) => l.language === language.code)].value"
                        :background-color="
                          !item.languages[item.languages.findIndex((l) => l.language === language.code)].value
                            ? 'rgba(251, 140, 0, 0.3)'
                            : undefined
                        "
                        rows="2"
                        auto-grow
                        filled
                        :label="`Question - ${language.name}`"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <div class="d-flex align-baseline">
                        <div class="translation-legend-icon"></div>
                        <div class="text-caption ml-2">{{ $t('translationMissingLegend') }}</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <v-row v-if="selectedTab === 0">
            <v-col>
              <v-btn ref="addQuestion" color="primary" @click="addQuestion(null)">{{ $t('addQuestion') }}</v-btn>
            </v-col>
          </v-row>
          <RequiredFieldsLegend />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import permissionMixin from '@/auth/permissionMixin';
import DialogueService from '@/services/dialogueService';
import CommonService from '@/services/commonService';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'DialogueModal',

  components: { SaveButton },
  mixins: [translation, accessibility, validationRulesMixin, permissionMixin],

  props: {
    dialogueId: {
      type: Number,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      title: '',
      formData: {
        name: '',
        description: '',
        normative: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        parameters: [],
      },
      originalFormData: {},
      selectedLanguage: 'fr',
      selectedTab: 0,
      languages: [],
      activeItems: [],
      displayYes: { fr: 'Oui', en: 'Yes' },
      displayNo: { fr: 'Non', en: 'No' },
      openList: [],
      alertLevels: [
        { text: this.$t('normalValue'), value: 'normal' },
        { text: this.$t('warning'), value: 'warning' },
        { text: this.$t('abnormalValue'), value: 'abnormal' },
      ],
    };
  },
  computed: {
    activeItem() {
      return this.activeItems.length ? this.activeItems[0] : null;
    },
    flatItems() {
      let result = [];
      let processChildren = (items) => {
        items.forEach((item) => {
          if (item.type === 'question') {
            result.push(item);
          }
          if (item.children?.length) {
            processChildren(item.children);
          }
        });
      };

      processChildren(this.formData.parameters);
      return result;
    },
    translationsMissing() {
      return this.flatItems.some((item) =>
        this.languages.some(
          (language) => !item.languages[item.languages.findIndex((l) => l.language === language.code)].value
        )
      );
    },
  },
  watch: {
    show: async function () {
      if (this.show) {
        if (this.dialogueId != null) {
          await this.editClicked();
        } else {
          this.init();
        }
        await this.getLanguagesList();
      }
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.title = this.$t('createNewDialogue');
      this.formData = {
        name: '',
        description: '',
        normative: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        parameters: [],
      };
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      this.selectedTab = 0;
      this.activeItems = [];
      this.openList = [];
      this.nextid = 1;
    },
    async getLanguagesList() {
      try {
        let languages = await CommonService.getLanguages();
        this.languages = languages;
      } catch (error) {
        this.error = error;
      }
    },
    async editClicked() {
      this.title = this.$t('editDialogue');
      this.showWaitModal = true;
      this.selectedTab = 0;
      this.activeItems = [];
      try {
        let dialogue = await DialogueService.getDialogue(this.dialogueId);
        this.formData.name = dialogue.name;
        this.formData.description = dialogue.description;
        this.formData.normative = dialogue.normative;
        this.formData.active = dialogue.active;
        this.formData.parameters = dialogue.parameters;
        this.formData.createdDate = dialogue.createdAt;
        this.formData.createdBy = dialogue.createdBy != null ? dialogue.createdBy.username : '';
        this.formData.modifiedDate = dialogue.updatedAt;
        this.formData.modifiedBy = dialogue.updatedBy != null ? dialogue.updatedBy.username : '';
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
        this.setNextId();
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async createOrUpdateAction() {
      if (this.isProcessing) return;
      var isValid = this.$refs.dialogueModalForm.validate();
      var isValidParameters = true;

      for (let obj of this.formData.parameters) {
        if (!this.validateParameters(obj)) {
          isValidParameters = false;
          break;
        }
      }
      if (isValid && isValidParameters) {
        this.showWaitModal = true;

        let serializeParameter = (item) => {
          let serialized;

          if (item.type === 'question') {
            serialized = {
              id: item.id,
              type: item.type,
              languages: item.languages.map((x) => {
                return {
                  language: x.language,
                  value: x.value,
                };
              }),
              children: item.children ? item.children.map(serializeParameter) : null,
            };
          } else {
            serialized = {
              id: item.id,
              type: item.type,
              value: item.value,
              detailResponse: item.detailResponse,
              endDialogueExecution: item.endDialogueExecution,
              children: item.children ? item.children.map(serializeParameter) : null,
              alertLevel: item.alertLevel,
            };
          }
          return serialized;
        };

        var data = {
          name: this.formData.name,
          description: this.formData.description,
          active: this.formData.active,
          normative: this.formData.normative,
          parameters: this.formData.parameters.map(serializeParameter),
        };
        this.isProcessing = true;

        try {
          if (this.dialogueId == null) {
            await DialogueService.createDialogue(data);
          } else {
            await DialogueService.updateDialogue(this.dialogueId, data);
          }
          this.closeDialog();
          this.$emit('refresh', true);
        } catch (error) {
          this.error = error;
        }
        this.showWaitModal = false;
        this.isProcessing = false;
      }
    },
    setNextId: function () {
      if (this.formData.parameters.length > 0) {
        this.nextid = this.findMaxId(this.formData.parameters) + 1;
      }
    },
    findMaxId: function (items) {
      let maxId = 0;
      items.forEach((item) => {
        maxId = Math.max(maxId, item.id);
        if (item.children?.length > 0) {
          maxId = Math.max(maxId, this.findMaxId(item.children));
        }
      });
      return maxId;
    },

    validateParameters: function (obj, parent = null) {
      let result = true;

      if (obj.type === 'question') {
        let fr = obj.languages.find((x) => x.language === 'fr');
        if (!fr || fr.value === '' || fr.value === undefined || fr.value === null) {
          this.activeItems = [obj];
          this.selectedLanguage = 'fr';
          result = false;
        }
      }
      for (let i = 0; i < obj.children?.length && result; i++) {
        if (obj.children?.length > 0) {
          let childResult = this.validateParameters(obj.children[i], parent ? [...parent, obj] : [obj]);
          result = result && childResult;
        }
      }
      return result;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },
    removeItemFromParameters: function (array, idToRemove) {
      for (let index = 0; index < array.length; index++) {
        let element = array[index];
        if (element.id === idToRemove) {
          array.splice(index, 1);
          return true;
        }

        if (element.children) {
          if (this.removeItemFromParameters(element.children, idToRemove)) {
            return true;
          }
        }
      }
      return false;
    },
    deleteQuestion() {
      this.removeItemFromParameters(this.formData.parameters, this.activeItems[0].id);
      this.activeItems = [];
    },
    addQuestion(parent) {
      let question = {
        id: this.nextid,
        type: 'question',
        languages: [
          { language: 'fr', value: '' },
          { language: 'en', value: '' },
        ],
        children: [
          {
            id: this.nextid + 1,
            type: 'response',
            value: 'yes',
            detailResponse: '',
            alertLevel: 'normal',
            endDialogueExecution: false,
            children: null,
          },
          {
            id: this.nextid + 2,
            type: 'response',
            value: 'no',
            alertLevel: 'normal',
            endDialogueExecution: false,
            children: null,
          },
        ],
      };
      this.nextid += 3;
      if (parent) {
        parent.children = parent.children || [];
        parent.children.push(question);
      } else {
        this.formData.parameters.push(question);
      }
      this.activeItems = [question];
    },
    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
    toggleExpand() {
      if (this.openList.length > 0) {
        this.openList = [];
      } else {
        let flatten = (items) => items.flatMap((x) => (x.children?.length ? [x, ...flatten(x.children)] : x));
        this.openList = flatten(this.formData.parameters).filter((x) => x.children?.length);
      }
    },
  },
};
</script>

<style scoped>
.tabs.v-tabs-items {
  overflow: visible;
}
.translation-legend-icon {
  background: #fedcb2;
  border: #dc5f26 solid 1px;
  height: 12px;
  width: 12px;
}
</style>
