<template>
  <v-container fluid>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <PageTitle :title="selectedPatientId ? $t('iotRealtime.realtimeDetails') : $t('iotRealtime.realtime')" />
      </v-col>
      <PatientPrintInfo :patient-id="Number(selectedPatientId)" />
    </v-row>

    <v-row v-if="selectedPatientId">
      <v-col cols="auto">
        <v-btn ref="closeIotRealtimeDetails" color="primary" exact :to="{ name: 'patientIotRealtime' }">
          <v-icon>mdi-arrow-left</v-icon>
          <span>{{ $t('backPrecedent') }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" class="mb-6" />

    <template v-if="!initDataLoading">
      <IotRealtimeDetails v-if="selectedPatientId" ref="iotRealtimeDetails" :patient-data="selectedPatientData" />

      <IotRealtimeGridContainer
        v-show="!selectedPatientId"
        ref="iotRealtimeGridContainer"
        :activity-types-codes="activityTypesCodes"
        :iot-real-time-data="iotRealTimeData"
        :cohorts="cohorts"
        @applyCohortFilter="getCohortsPatientsIotRealtime"
        @viewPatientIotRealtimeDetail="viewPatientIotRealtimeDetail"
      />
    </template>
  </v-container>
</template>

<script>
import Vue from 'vue';

import cohortsService from '@/services/cohortsService';
import iotRealtimeMixin from './iotRealtimeMixin';
import iotRealtimeService from '@/services/iotRealtimeService';
import translationMixin from '@/translationMixin';
import wsNotificationsMixin from '@/wsNotificationsMixin';

import { differenceInMinutes } from 'date-fns';

import IotRealtimeDetails from './Details/IotRealtimeDetails.vue';
import IotRealtimeGridContainer from './Grid/IotRealtimeGridContainer.vue';
import PatientPrintInfo from '../Patient/PatientPrintInfo.vue';

let selectedCohortIds = [];

export default {
  name: 'PatientIotRealtime',
  components: {
    IotRealtimeDetails,
    IotRealtimeGridContainer,
    PatientPrintInfo,
  },

  mixins: [iotRealtimeMixin, translationMixin, wsNotificationsMixin],

  data() {
    return {
      activityTypesCodes: [],
      cohorts: [],
      iotRealTimeData: [],
      error: null,
      selectedPatientData: null,
      timerToRefreshRelativeDates: null,
      initDataLoading: true,
      showWaitModal: false,
      oneMinuteInMilliseconds: 1000 * 60,
    };
  },

  computed: {
    selectedPatientId() {
      return this.$route.params?.patientId;
    },
  },

  watch: {
    '$route.params.patientId': async function (selectedPatientId) {
      if (selectedPatientId) {
        let selectedPatientData = this.iotRealTimeData.find((x) => x.patient.id === parseInt(selectedPatientId));

        if (!selectedPatientData) {
          selectedPatientData = await this.getPatientIotRealtime();
        }

        this.selectedPatientData = selectedPatientData;
      }

      if ((!selectedPatientId && this.activityTypesCodes.length === 0) || this.iotRealTimeData.length === 0) {
        await this.init();
      }
    },
  },

  async created() {
    await this.init();
    this.startTimer();
    this.subscribeToPatientIotRealtime();
  },

  beforeDestroy() {
    this.stopTimer();
  },

  methods: {
    onPatientIotRealtimeData: function (webSocketData) {
      const newPatientData = webSocketData?.data;

      if (newPatientData?.areDelayedValues) {
        if (this.selectedPatientData) {
          Vue.set(
            //Used Vue.set to avoid props reactivity issues
            this.selectedPatientData,
            'newChartData',
            newPatientData.patientActivityIotRealtime
          );
        }

        return;
      }

      this.iotRealTimeData.forEach((currentIotRealtime) => {
        if (currentIotRealtime.patient.id === newPatientData?.patientId) {
          this.setWebsocketData(currentIotRealtime, newPatientData);
        }
      });

      if (this.selectedPatientId == newPatientData.patientId) {
        this.setWebsocketData(this.selectedPatientData, newPatientData);
      }
    },

    setWebsocketData: async function (currentIotRealtime, webSocketData) {
      const {
        patientActivityIotRealtime: newPatientActivityIotRealtime = null,
        lastVitalSignsSyncData: newBiotbeatSync = null,
      } = webSocketData;

      const currentPatientActivityIotRealtime = currentIotRealtime?.patientActivityIotRealtime;
      const currentDatetime = new Date();

      if (newBiotbeatSync) {
        newBiotbeatSync.timeDifference = differenceInMinutes(
          currentDatetime,
          new Date(newBiotbeatSync.lastDataDatetime)
        );

        Vue.set(
          //Used Vue.set to avoid props reactivity issues
          currentIotRealtime,
          'lastVitalSignsSyncData',
          newBiotbeatSync
        );
      }

      if (newPatientActivityIotRealtime) {
        Object.keys(newPatientActivityIotRealtime)?.forEach((key) => {
          const newPatientData = newPatientActivityIotRealtime[key];

          if (!newPatientData?.values?.some((x) => x === null || x === undefined)) {
            currentPatientActivityIotRealtime[key] = newPatientActivityIotRealtime[key];

            if (currentPatientActivityIotRealtime[key].lastDataDatetime) {
              const currentObs = currentPatientActivityIotRealtime[key];

              Vue.set(
                //Used Vue.set to avoid props reactivity issues
                currentObs,
                'timeDifference',
                differenceInMinutes(currentDatetime, new Date(currentObs.lastDataDatetime))
              );
            }
          }
        });

        if (this.selectedPatientId == webSocketData.patientId) {
          Vue.set(
            //Used Vue.set to avoid props reactivity issues
            this.selectedPatientData,
            'newChartData',
            newPatientActivityIotRealtime
          );
        }
      }
    },

    init: async function () {
      this.initDataLoading = true;
      this.showWaitModal = true;

      try {
        const selectedPatientDataPromise = this.selectedPatientId
          ? iotRealtimeService.getPatientIotRealTime(this.selectedPatientId)
          : Promise.resolve(null);

        const activityTypesCodesPromise = !this.selectedPatientId
          ? iotRealtimeService.getRealtimeActivityTypes()
          : Promise.resolve([]);

        const iotRealtimePromise = !this.selectedPatientId
          ? iotRealtimeService.getAllIotsRealtime({ cohortIds: selectedCohortIds })
          : Promise.resolve([]);

        const cohortsPromise = !this.selectedPatientId ? cohortsService.getCohorts() : Promise.resolve([]);

        const [selectedPatientData, activityTypesCodes, iotRealtime, allCohorts] = await Promise.all([
          selectedPatientDataPromise,
          activityTypesCodesPromise,
          iotRealtimePromise,
          cohortsPromise,
        ]);

        this.selectedPatientData = selectedPatientData;
        this.activityTypesCodes = activityTypesCodes;
        this.iotRealTimeData = iotRealtime.sort(
          (a, b) =>
            a.patient.lastName.localeCompare(b.patient.lastName) ||
            a.patient.firstName.localeCompare(b.patient.firstName)
        );
        this.cohorts = allCohorts;

        this.resetIotRealtimeRelativeDates();
      } catch (error) {
        this.error = error;
      }

      this.initDataLoading = false;
      this.showWaitModal = false;
    },

    getCohortsPatientsIotRealtime: async function (cohortIds) {
      this.showWaitModal = true;

      try {
        const data = {
          cohortIds: cohortIds,
        };

        const iotRealtime = await iotRealtimeService.getAllIotsRealtime(data);

        this.iotRealTimeData = iotRealtime.sort(
          (a, b) =>
            a.patient.lastName.localeCompare(b.patient.lastName) ||
            a.patient.firstName.localeCompare(b.patient.firstName)
        );
        this.resetIotRealtimeRelativeDates();
        selectedCohortIds = cohortIds;
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    getPatientIotRealtime: async function () {
      let data;

      try {
        data = await iotRealtimeService.getPatientIotRealTime(this.selectedPatientId);
      } catch (error) {
        this.error = error;
      }

      return data;
    },

    viewPatientIotRealtimeDetail: async function (patientId) {
      this.$router.push({
        name: 'patientIotRealtime',
        params: { patientId: patientId },
      });
    },

    setPatientVitalSignsRelativeDates: function (patientData, currentDatetime) {
      if (patientData?.lastVitalSignsSyncData) {
        Vue.set(
          //Used Vue.set to avoid props reactivity issues
          patientData.lastVitalSignsSyncData,
          'timeDifference',
          differenceInMinutes(currentDatetime, new Date(patientData.lastVitalSignsSyncData.lastDataDatetime))
        );
      }

      Object.keys(patientData?.patientActivityIotRealtime)?.forEach((key) => {
        const lastDataDatetime = patientData.patientActivityIotRealtime[key]?.lastDataDatetime;

        if (lastDataDatetime) {
          Vue.set(
            //Used Vue.set to avoid props reactivity issues
            patientData.patientActivityIotRealtime[key],
            'timeDifference',
            differenceInMinutes(currentDatetime, new Date(lastDataDatetime))
          );
        }
      });
    },

    resetIotRealtimeRelativeDates: function () {
      const currentDatetime = new Date();

      if (this.selectedPatientData) {
        this.setPatientVitalSignsRelativeDates(this.selectedPatientData, currentDatetime);
      }

      this.iotRealTimeData.forEach((item) => {
        this.setPatientVitalSignsRelativeDates(item, currentDatetime);

        return {
          ...item,
        };
      });
    },

    startTimer: function () {
      this.timerToRefreshRelativeDates = setInterval(this.resetIotRealtimeRelativeDates, this.oneMinuteInMilliseconds);
    },

    stopTimer: function () {
      clearInterval(this.timerToRefreshRelativeDates);
    },
  },
};
</script>
