<template>
  <v-container>
    <ConfirmationModal
      :question="$t('resources.confirmDeleteResource')"
      :title="$t('delete')"
      :show="showDelete"
      @cancel="showDelete = false"
      @ok="deleteSelectedResource"
    />

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('resources.Resources') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.resource)">
      <v-col>
        <v-btn ref="addResource" color="primary" @click="addResourceAction(null)">
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />

    <v-row>
      <v-col>
        <DataGrid
          :items="resources"
          :columns="columns"
          grid-name="resources_grid"
          :show-select="false"
          :items-per-page="25"
          :data-loading="showWaitModal"
        >
          <template #item.isActive="{ item }">
            <v-simple-checkbox v-model="item.isAtive" disabled />
          </template>
          <template #item.actions="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can(permissions.actions.change, permissions.subjects.resource)"
                  :ref="`resource_${item.id}`"
                  @click="showEdit(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.resource)"
                  :ref="`resource_${item.id}`"
                  @click="showDeleteModal(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <ResourcesModal
      :resource-id="selectedResourceId"
      :resource-data="selectedResourceData"
      :show.sync="showAddModal"
      @refresh="init()"
    />
  </v-container>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import ResourcesModal from './ResourcesModal';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import ResourceService from '@/services/resourceService';
import permissionMixin from '@/auth/permissionMixin';

export default {
  name: 'ResourcesGrid',
  components: { DataGrid, ResourcesModal },
  mixins: [translationMixin, accessibility, permissionMixin],
  props: {},
  data() {
    return {
      showDelete: false,
      showAddModal: false,

      error: null,
      selectedResourceId: null,
      showWaitModal: false,
      resources: [],
      selectedResourceData: {},
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'resourceName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'resourceDescription',
          value: 'description',
          filterable: false,
          visible: true,
        },
        {
          text: 'resourceFormat',
          value: 'format',
          filterable: true,
        },
        {
          text: 'createdDate',
          value: 'createdAt',
          filterable: true,
          visible: true,
        },
        {
          text: 'modifiedDate',
          value: 'modifiedDate',
          filterable: true,
          visible: true,
        },
      ];
      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.resource) ||
        this.$can(this.permissions.actions.delete, this.permissions.subjects.resource)
      ) {
        columns.push({
          text: 'actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },

  created: async function () {
    await this.init();
  },

  methods: {
    async init() {
      this.resources = [];
      this.showWaitModal = true;
      this.showDelete = false;
      this.showAddModal = false;

      try {
        this.resources = await ResourceService.getResources();
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    getResourceById: function (resources, id) {
      return resources.find((resource) => resource.id === id);
    },

    addResourceAction: function (id) {
      this.selectedResourceId = id;
      this.showAddModal = true;
    },

    showEdit: async function (id) {
      this.selectedResourceId = id;

      const {
        name: resourceName,
        description: resourceDescription,
        format: resourceFormat,
        file: resourceFile,
        original_file: resourceOriginalFile,
      } = this.getResourceById(this.resources, this.selectedResourceId);

      this.selectedResourceData = {
        resourceName,
        resourceDescription,
        resourceFormat,
        resourceFile,
        resourceOriginalFile,
      };
      this.showAddModal = true;
    },

    showDeleteModal: function (id) {
      this.selectedResourceId = id;
      this.showDelete = true;
    },

    deleteSelectedResource: async function () {
      try {
        await ResourceService.deleteResource(this.selectedResourceId);
        this.selectedResourceId = null;
        this.showDelete = false;
        this.resources = await ResourceService.getResources();
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
