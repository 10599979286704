<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />
    <v-row align="start">
      <v-col align-self="start" :cols="12">
        <v-select
          :id="getIdByName('statusLevels')"
          ref="statusLevels"
          v-model="statusLevelFilters"
          class=""
          clearable
          multiple
          :label="$t('statusLevels')"
          :items="alertStatusLevels"
          :item-text="(item) => $t(item.name)"
          item-value="id"
          outlined
          small-chips
          deletable-chips
          :no-data-text="$t('noDataAvailable')"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col align-self="start" :cols="12">
        <v-select
          :id="getIdByName('cohortMonitoring')"
          ref="cohortMonitoring"
          v-model="cohortFilters"
          class=""
          clearable
          multiple
          :label="$t('cohortMonitoring')"
          :items="cohorts"
          item-text="name"
          item-value="id"
          outlined
          small-chips
          deletable-chips
          :no-data-text="$t('noDataAvailable')"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <div class="d-flex flex-row">
          <v-btn ref="apply" color="primary" class="mt-2 ml-auto" :disabled="!isValid" @click.stop="emitFilter()">
            <v-icon> mdi-filter </v-icon>
            {{ $t('apply') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import cohortsService from '@/services/cohortsService';
import patientMonitoringService from '@/services/patientMonitoringService';
import { StatusLevels } from '../PatientMonitoring/constants';

export default {
  name: 'PatientMonitoringAlertFilterRealtime',
  components: {},
  mixins: [translationMixin, accessibility],

  props: {},

  data() {
    return {
      statusLevelFilters: [],
      allStatusLevels: [],
      alertStatusLevels: [],
      cohorts: [],
      cohortFilters: [],
      error: null,
      showWaitModal: false,
      isValid: true,
      availableStatusLevels: [StatusLevels.AL, StatusLevels.CH, StatusLevels.WE],
    };
  },

  created: function () {
    this.init();
  },

  methods: {
    async init() {
      this.showWaitModal = true;

      try {
        let [allCohorts, allStatusLevels] = await Promise.all([
          cohortsService.getCohorts(),
          patientMonitoringService.getStatusLevels(),
        ]);

        this.cohorts = allCohorts;
        this.allStatusLevels = allStatusLevels;
        this.alertStatusLevels = this.allStatusLevels.filter((x) => {
          let exists = this.availableStatusLevels.find((item) => item === x.code);
          if (exists) {
            return true;
          }
          return false;
        });
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    emitFilter: function () {
      var statusLevelFilters = this.statusLevelFilters.length === 0 ? [] : this.statusLevelFilters;
      var cohortFilters = this.cohortFilters.length === 0 ? [] : this.cohortFilters;

      var result = {
        statusLevelIds: statusLevelFilters,
        cohortIds: cohortFilters,
      };

      this.$emit('filter', result);
    },
  },
};
</script>

<style scoped></style>
