<template>
  <div>
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col>
        <v-btn ref="closeMonitoringDetails" color="primary" exact @click="backButton">
          <v-icon>mdi-arrow-left</v-icon>
          <span>{{ $t('backPrecedent') }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="patientInfo" align="center" :class="{ 'pb-2': !$vuetify.breakpoint.xs }">
      <v-col cols="auto no-print">
        <div>
          <span :class="$vuetify.breakpoint.smAndUp ? ['text-h6'] : []">
            {{ patientInfo.lastName }}, {{ patientInfo.firstName }}
          </span>
          <detail-field
            v-if="patientInfo.roombed"
            class="mt-0"
            :value="patientInfo.roombed"
            readonly
            :label="$t('roombed')"
          />
        </div>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.xs">
        <v-btn icon @click="showPatientSheet = true"><v-icon>mdi-information</v-icon></v-btn>
      </v-col>

      <v-col v-if="!$vuetify.breakpoint.xs && availableTabs" class="print-margin-50">
        <v-select
          outlined
          :items="availableTabs"
          item-text="name"
          :value="availableTabs[selectedTab]"
          return-object
          hide-details
          @change="selectedTab = availableTabs.indexOf($event)"
        ></v-select>
      </v-col>
    </v-row>

    <template v-if="availableTabs">
      <v-row v-if="$vuetify.breakpoint.xs">
        <v-col>
          <v-select
            dense
            outlined
            :items="availableTabs"
            item-text="name"
            :value="availableTabs[selectedTab]"
            return-object
            hide-details
            @change="selectedTab = availableTabs.indexOf($event)"
          ></v-select>
        </v-col>
        <v-col v-if="!availableTabs[selectedTab].isPatient && !availableTabs[selectedTab].isMessages" cols="auto">
          <v-btn icon @click="showFilterSheet = true"><v-icon>mdi-filter</v-icon></v-btn>
        </v-col>
      </v-row>

      <PatientMonitoringFilter
        v-if="
          $vuetify.breakpoint.smAndUp && !availableTabs[selectedTab].isPatient && !availableTabs[selectedTab].isMessages
        "
        class="mt-1"
        :initial-values="innerFilters"
        :use-monitoring-status="false"
        @filter="onMonitoringFilter"
      />

      <v-navigation-drawer v-model="showFilterSheet" fixed temporary right touchless>
        <v-card flat>
          <v-card-title>{{ $t('filters') }}</v-card-title>
          <v-card-text>
            <PatientMonitoringFilter
              :initial-values="innerFilters"
              :use-monitoring-status="false"
              @filter="onMonitoringFilter"
            />
          </v-card-text>
        </v-card>
      </v-navigation-drawer>

      <v-bottom-sheet v-model="showPatientSheet" scrollable>
        <v-card max-height="60vh">
          <v-card-title>{{ $t('patient') }}</v-card-title>
          <v-card-text>
            <PatientDetails :patient-id="patientId" />
          </v-card-text>
        </v-card>
      </v-bottom-sheet>

      <v-row>
        <v-col class="print-padding-0">
          <v-tabs-items v-model="selectedTab" class="monitoring-tabs" touchless>
            <v-tab-item v-for="(tab, index) in availableTabs" :key="index" :value="index" :transition="false">
              <template v-if="tab.isPatient">
                <v-card>
                  <v-card-text>
                    <PatientDetails ref="patientDetails" :patient-id="patientId" />
                  </v-card-text>
                </v-card>

                <v-card class="mt-5">
                  <v-card-text>
                    <PatientNotes ref="patientNotes" :patient-id="patientId" />
                  </v-card-text>
                </v-card>
              </template>

              <template v-if="getActivityIdOnActivityName(tab.activityName)">
                <PatientIncontinence
                  v-if="tab.activityTypeCode === activityTypesCodes.INC"
                  ref="patientIncontinence"
                  :patient-id="patientId"
                  :filters="innerFilters"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  @update:request-filters="updateFilters"
                />
                <PatientFallDetection
                  v-if="tab.activityTypeCode === activityTypesCodes.FDE"
                  ref="patientFallDetection"
                  :patient-id="patientId"
                  :filters="innerFilters"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  @update:request-filters="updateFilters"
                />

                <PatientAnamnese
                  v-if="tab.activityTypeCode === activityTypesCodes.DLG"
                  :ref="`patientAnamnese_${tab.activityId}`"
                  :patient-id="patientId"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  :filters="innerFilters"
                  @update:request-filters="updateFilters"
                />

                <PatientBloodPressure
                  v-if="tab.activityTypeCode === activityTypesCodes.APR"
                  ref="patientBloodPressure"
                  :patient-id="patientId"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  :filters="innerFilters"
                  @update:request-filters="updateFilters"
                />

                <PatientSaturation
                  v-if="tab.activityTypeCode === activityTypesCodes.SAT"
                  ref="patientSaturation"
                  :patient-id="patientId"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  :filters="innerFilters"
                  @update:request-filters="updateFilters"
                />

                <PatientHeartRate
                  v-if="tab.activityTypeCode === activityTypesCodes.CFR"
                  ref="patientHeartRate"
                  :patient-id="patientId"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  :filters="innerFilters"
                  @update:request-filters="updateFilters"
                />

                <PatientBodyTemperature
                  v-if="tab.activityTypeCode === activityTypesCodes.BDT"
                  ref="patientBodyTemperature"
                  :patient-id="patientId"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  :filters="innerFilters"
                  @update:request-filters="updateFilters"
                />

                <PatientRespiratoryRate
                  v-if="tab.activityTypeCode === activityTypesCodes.RES"
                  ref="patientRespiratoryRate"
                  :patient-id="patientId"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  :filters="innerFilters"
                  @update:request-filters="updateFilters"
                />

                <PatientPrescription
                  v-if="tab.activityTypeCode === activityTypesCodes.RXA"
                  ref="patientPrescription"
                  :patient-id="patientId"
                  :filters="innerFilters"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  @update:request-filters="updateFilters"
                />

                <PatientWeight
                  v-if="tab.activityTypeCode === activityTypesCodes.WEI"
                  ref="patientWeight"
                  :patient-id="patientId"
                  :filters="innerFilters"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  @update:request-filters="updateFilters"
                />

                <PatientCapillaryGlycemia
                  v-if="tab.activityTypeCode === activityTypesCodes.GLY"
                  ref="PatientCapillaryGlycemia"
                  :patient-id="patientId"
                  :filters="innerFilters"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  @update:request-filters="updateFilters"
                />

                <PatientPrescriptiveLearning
                  v-if="tab.activityTypeCode === activityTypesCodes.PRE"
                  ref="patientPrescriptiveLearning"
                  :patient-id="patientId"
                  :filters="innerFilters"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  @update:request-filters="updateFilters"
                />
                <PatientPicture
                  v-if="tab.activityTypeCode === activityTypesCodes.PHT"
                  ref="patientPicture"
                  :patient-id="patientId"
                  :filters="innerFilters"
                  :activity-id="getActivityIdOnActivityName(tab.activityName)"
                  @update:request-filters="updateFilters"
                />
              </template>

              <PatientMessage
                v-if="tab.isMessages"
                ref="patientMessages"
                :is-component-active="tab.index === selectedTab"
                :patient-id="patientId"
                :selected-chat-id="detailId"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="!isFetching">
      <v-row>
        <v-col>{{ $t('patientNotFound') }}</v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import PatientDetails from './PatientMonitoringTabs/PatientDetails.vue';
import PatientCapillaryGlycemia from './PatientMonitoringTabs/PatientCapillaryGlycemia.vue';
import PatientHeartRate from './PatientMonitoringTabs/PatientHeartRate.vue';
import PatientSaturation from './PatientMonitoringTabs/PatientSaturation.vue';
import PatientBloodPressure from './PatientMonitoringTabs/PatientBloodPressure.vue';
import PatientBodyTemperature from './PatientMonitoringTabs/PatientBodyTemperature.vue';
import PatientRespiratoryRate from './PatientMonitoringTabs/PatientRespiratoryRate.vue';
import PatientPrescription from './PatientMonitoringTabs/PatientPrescription.vue';
import PatientWeight from './PatientMonitoringTabs/PatientWeight.vue';
import PatientMessage from './PatientMonitoringTabs/PatientMessageTab/PatientMessage.vue';
import PatientAnamnese from './PatientMonitoringTabs/PatientAnamnese.vue';
import PatientFallDetection from './PatientMonitoringTabs/PatientFallDetection.vue';
import PatientIncontinence from './PatientMonitoringTabs/PatientIncontinence.vue';
import PatientPicture from './PatientMonitoringTabs/PatientPicture.vue';
import PatientPrescriptiveLearning from './PatientMonitoringTabs/PatientPrescriptiveLearning.vue';
import PatientMonitoringFilter from './PatientMonitoringFilter.vue';
import { ActivityTypes, MonitoringTypes } from './constants';
import PatientNotes from './PatientMonitoringTabs/PatientNotes.vue';
export default {
  name: 'MonitoringDetails',
  components: {
    PatientDetails,
    PatientNotes,
    PatientCapillaryGlycemia,
    PatientHeartRate,
    PatientSaturation,
    PatientBloodPressure,
    PatientBodyTemperature,
    PatientRespiratoryRate,
    PatientPrescription,
    PatientWeight,
    PatientMessage,
    PatientAnamnese,
    PatientFallDetection,
    PatientIncontinence,
    PatientMonitoringFilter,
    PatientPicture,
    PatientPrescriptiveLearning,
  },
  mixins: [translation],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
    detailType: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    detailId: {
      type: Number,
      required: false,
      default: undefined,
    },
    filters: {
      type: Object,
      required: true,
    },
    patientInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
    activities: {
      type: Array,
      required: false,
      default: undefined,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedTab: undefined,
      innerFilters: null,
      activityTypesCodes: ActivityTypes,
      activityTypesCodesFDE: ActivityTypes.FDE,

      showFilterSheet: false,
      showPatientSheet: false,
    };
  },
  computed: {
    availableTabs() {
      if (!this.activities) return undefined;

      let allowedActivityTypes = [
        ActivityTypes.FDE,
        ActivityTypes.DLG,
        ActivityTypes.CFR,
        ActivityTypes.SAT,
        ActivityTypes.APR,
        ActivityTypes.BDT,
        ActivityTypes.RES,
        ActivityTypes.RXA,
        ActivityTypes.WEI,
        ActivityTypes.INC,
        ActivityTypes.PRE,
        ActivityTypes.PHT,
        ActivityTypes.GLY,
      ];

      let monitoringTabs = this.activities
        .filter((x) => allowedActivityTypes.includes(x.activityTypeCode))
        .map((x) => {
          return {
            name: x.displayName,
            activityName: x.name,
            activityTypeCode: x.activityTypeCode,
            activityId: x.id,
            url: x.id,
          };
        });

      let tabs = [
        {
          name: this.$t('patient'),
          isPatient: true,
        },
        ...monitoringTabs,
        {
          name: this.$t('communications'),
          isMessages: true,
          url: MonitoringTypes.CONVERSATIONS,
        },
      ];

      tabs.forEach((t, i) => {
        t.index = i;
      });

      return tabs;
    },
  },
  watch: {
    detailType: {
      immediate: true,
      handler() {
        this.selectedTab = this.detailTypeToTabIndex(this.detailType);
      },
    },
    activities: {
      immediate: true,
      handler() {
        this.selectedTab = this.detailTypeToTabIndex(this.detailType);
      },
    },
    selectedTab() {
      if (this.availableTabs) {
        let newDetailType = this.availableTabs[this.selectedTab].url
          ? String(this.availableTabs[this.selectedTab].url)
          : this.availableTabs[this.selectedTab].url;

        if (newDetailType !== this.detailType) {
          this.$emit('update:detail-type', newDetailType);
        }
      }
    },
    filters: {
      immediate: true,
      handler() {
        this.onMonitoringFilter(this.filters);
      },
    },
    '$vuetify.breakpoint.xs': function (newValue) {
      if (!newValue) {
        this.showFilterSheet = false;
        this.showPatientSheet = false;
      }
    },
  },

  destroyed() {
    this.$emit('reset-filters', null);
  },

  methods: {
    onMonitoringFilter: function (filters) {
      this.innerFilters = filters;
      this.showFilterSheet = false;
    },

    backButton() {
      this.$emit('navigateToGrid', false);
      this.$router.push({ name: 'PatientMonitoringGrid' });
    },

    detailTypeToTabIndex: function (detailType) {
      if (!this.availableTabs) return;

      let patientTab = this.availableTabs.find((x) => x.isPatient);

      if (!detailType) {
        return patientTab.index;
      }

      if (typeof detailType === 'string') {
        detailType = detailType.toLowerCase();
      }

      let tab = this.availableTabs.find((x) => x.url == detailType);

      return tab ? tab.index : patientTab.index;
    },

    getActivityIdOnActivityName(activityName) {
      return this.availableTabs.find((x) => x.activityName === activityName).activityId;
    },
    updateFilters(newFilters) {
      this.innerFilters = newFilters;
    },
  },
};
</script>

<style scoped>
.monitoring-tabs.v-tabs-items {
  background: transparent;
  overflow: visible;
}
</style>
