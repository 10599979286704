<template>
  <v-sheet dark class="background-dark navigation-container">
    <UserPreferences ref="userPreferences" :show.sync="showPreferences" />
    <img ref="logoImg" class="logo-img ml-4 mt-4" :src="logoPath" />
    <div class="navigation-items">
      <v-list>
        <v-menu offset-y bottom>
          <template #activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon medium>mdi-account-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ userInfo.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ userInfo.username }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item ref="preferences" @click="showPreferencesModal()">
              <v-list-item-icon><v-icon>mdi-account-cog</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('userPreferences') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item ref="logout" @click.stop="logout()">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('logout') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider></v-divider>

        <v-menu
          v-if="$vuetify.breakpoint.smAndUp"
          ref="notificationMenu"
          v-model="showNotificationModal"
          offset-y
          bottom
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-bell </v-icon>
                <NotificationCount ref="notificationCount" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('notificationsMenu') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <NotificationModal
            v-if="showNotificationModal"
            ref="notificationModal"
            class="notification-modal"
            :close-dialog="closeDialog"
            @read-status-changed="$refs.notificationCount.refreshUnreadNotificationCount()"
            @ui-updated="$refs.notificationMenu.onResize()"
            @notifs-deleted="handleNotifsDeleted"
          />
        </v-menu>
      </v-list>

      <v-list shaped>
        <v-subheader v-if="$can(permissions.actions.view, permissions.subjects.patientMonitoring)">{{
          $t('monitoring')
        }}</v-subheader>
        <v-list-item
          v-if="$can(permissions.actions.view, permissions.subjects.patientMonitoring)"
          ref="patientMonitoring"
          to="/patientmonitoring"
        >
          <v-list-item-icon>
            <v-icon>mdi-monitor-eye</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('patientMonitoring') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can(permissions.actions.view, permissions.subjects.patientMonitoring) && realTimeFeature"
          ref="patientMonitoringAlert"
          to="/patientmonitoringalert"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <div class="sidenav-long-string">
                {{ $t('patientMonitoringAlert.title') }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can(permissions.actions.view, permissions.subjects.patientMonitoring) && iotRealtimeFeature"
          ref="patientIotRealtime"
          to="/patientiotrealtime"
        >
          <v-list-item-icon>
            <v-icon>mdi-heart-pulse</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <div class="sidenav-long-string">
                {{ $t('iotRealtime.realtime') }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can(permissions.actions.view, permissions.subjects.patientMonitoring) && demoFeature && hasLeomedUrl"
          ref="PatientVitalSigns"
          to="/patientvitalsigns"
        >
          <v-list-item-icon>
            <v-icon>mdi-heart-pulse</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('patientVitalSigns') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader v-if="managementHeaderVisible">{{ $t('management') }}</v-subheader>
        <v-list-item v-if="$can(permissions.actions.view, permissions.subjects.patient)" ref="patient" to="/patient">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('patients') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can(permissions.actions.view, permissions.subjects.healthWorker)"
          ref="healthcareProfessional"
          to="/healthcareprofessional"
        >
          <v-list-item-icon><v-icon>mdi-stethoscope</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <div class="sidenav-long-string">
                {{ $t('professionalManagement') }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can(permissions.actions.view, permissions.subjects.cohort)" ref="cohort" to="/cohort">
          <v-list-item-icon><v-icon>mdi-account-group</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('cohorts') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can(permissions.actions.view, permissions.subjects.iotDevice)" ref="iot" to="/iot">
          <v-list-item-icon><v-icon>mdi-devices</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('iot') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can(permissions.actions.view, permissions.subjects.activity)" ref="activity" to="/activity">
          <v-list-item-icon><v-icon>mdi-clipboard-pulse-outline</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('activities') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can(permissions.actions.view, permissions.subjects.protocol)" ref="protocol" to="/protocol">
          <v-list-item-icon><v-icon>mdi-chart-timeline</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('protocols') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can(permissions.actions.view, permissions.subjects.dialogue)" ref="dialogue" to="/dialogue">
          <v-list-item-icon><v-icon>mdi-forum-outline</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('dialogues') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item
          v-if="$can(permissions.actions.view, permissions.subjects.vocalMessage)"
          ref="vocalMessage"
          to="/vocalmessage"
        > -->
        <!-- <v-list-item-icon><v-icon>mdi-speaker-wireless</v-icon></v-list-item-icon> -->
        <!-- <v-list-item-content>
            <v-list-item-title>
              <div class="sidenav-long-string">
                {{ $t('vocalMessages') }}
              </div>
            </v-list-item-title>
          </v-list-item-content> -->
        <!-- </v-list-item> -->

        <v-list-item
          v-if="$can(permissions.actions.view, permissions.subjects.resource)"
          ref="resources"
          to="/resources"
        >
          <v-list-item-icon><v-icon>mdi-file-document</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('resources.Resources') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$can(permissions.actions.view, permissions.subjects.user)" ref="user" to="/user">
          <v-list-item-icon><v-icon>mdi-laptop</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('user.users') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item v-if="userInfo.isSuperUser" ref="administration" to="/administration">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('configuration') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </div>
  </v-sheet>
</template>

<script>
import translationMixin from '@/translationMixin';
import permissions from '@/auth/permissionMixin';
import NotificationCount from '@/components/Notification/NotificationCount';
import NotificationModal from '@/components/Notification/NotificationModal';
import UserPreferences from '@/components/User/UserPreferences.vue';
import auth from '@/auth/auth';
import applicationService from '@/services/applicationService';

export default {
  name: 'SideNavigation',
  components: { NotificationCount, NotificationModal, UserPreferences },
  mixins: [translationMixin, permissions],
  data() {
    return {
      oldLogStatus: false,
      logoPath: '/static/img/logo.png',
      showPreferences: false,
      error: null,
      userInfo: {
        username: '',
        name: '',
        isSuperUser: false,
      },
      demoFeature: false,
      hasLeomedUrl: false,
      realTimeFeature: false,
      iotRealtimeFeature: false,

      showNotificationModal: false,
    };
  },
  computed: {
    managementHeaderVisible() {
      return (
        this.$can(this.permissions.actions.view, this.permissions.subjects.patient) ||
        this.$can(this.permissions.actions.view, this.permissions.subjects.healthWorker) ||
        this.$can(this.permissions.actions.view, this.permissions.subjects.cohort) ||
        this.$can(this.permissions.actions.view, this.permissions.subjects.iotDevice) ||
        this.$can(this.permissions.actions.view, this.permissions.subjects.activity) ||
        this.$can(this.permissions.actions.view, this.permissions.subjects.protocol) ||
        this.$can(this.permissions.actions.view, this.permissions.subjects.dialogue) ||
        this.$can(this.permissions.actions.view, this.permissions.subjects.user) ||
        this.$can(this.permissions.actions.view, this.permissions.subjects.resource) ||
        this.isSuperUser
      );
    },
  },
  watch: {
    showNotificationModal() {
      if (this.showNotificationModal) {
        this.$refs.notificationCount.resetTimer();
      }
    },
  },

  mounted() {
    this.loadUserInfo();
    this.loadDemoFeatureConfig();
  },
  methods: {
    showPreferencesModal: function () {
      this.showPreferences = true;
    },

    logout: function () {
      auth.signOut();
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
    },

    closeDialog: function () {
      this.showNotificationModal = false;
    },
    async loadUserInfo() {
      let attributes = await auth.getUserAttributes();
      this.userInfo.username = attributes.username;
      this.userInfo.name = `${attributes.given_name} ${attributes.family_name}`;
      this.userInfo.isSuperUser = attributes['custom:admin'] === 'true';
    },
    async loadDemoFeatureConfig() {
      let demoFeatureConfig = await applicationService.getDemoFeatureConfig();
      this.demoFeature = demoFeatureConfig.demoFeature;
      this.realTimeFeature = demoFeatureConfig.realTimeFeature;
      this.iotRealtimeFeature = demoFeatureConfig.iotRealtimeFeature;

      if (demoFeatureConfig.leomedUrl) {
        this.hasLeomedUrl = true;
      }
    },
    handleNotifsDeleted() {
      this.$emit('show-snackbar');
    },
  },
};
</script>

<style scoped>
.navigation-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.navigation-items {
  overflow-y: auto;
  width: 100%;
}

.sidenav-long-string {
  white-space: pre-line;
}

.logo-img {
  height: 80px;
}

.notification-modal {
  width: 600px;
}
</style>
