<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <v-menu v-if="isChatAvailable" ref="vMenuNewChatRef" offset-y>
      <template #activator="{ on, attrs }">
        <v-btn class="ma-2" v-bind="attrs" v-on="on"> {{ $t('newChat') }}<v-icon>mdi-chevron-down </v-icon> </v-btn>
      </template>
      <v-list v-if="availableParticipants">
        <template v-for="(participant, i) in availableParticipants">
          <div v-if="i === 1" :key="`divider-${i}`">
            <v-divider></v-divider>
            <v-subheader class="text--secondary">
              <span v-if="availableCaregivers.length === 1">{{ $t('caregiver') }}</span>
              <span v-if="availableCaregivers.length > 1">{{ $t('caregivers') }}</span>
            </v-subheader>
          </div>
          <v-list-item
            :key="`available-participant-${participant.type}-${participant.id}`"
            :value="participant"
            :disabled="!participant.isActive"
            @click="addNewChat(participant)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="getFullName(participant)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <v-card flat class="chat-list-container" justify="space-between">
      <v-row class="mt-2 mb-2">
        <v-col
          class="no-print"
          cols="4"
          style="max-height: 100%; max-width: 30vw; overflow-y: auto; overflow-x: hidden"
        >
          <v-list two-line>
            <v-list-item-group v-model="selectedChat" active-class="primary--text">
              <v-subheader class="text--secondary">
                {{ $t('activeChats') }}
              </v-subheader>
              <div v-if="formattedChats.activeChats.length > 0">
                <div v-for="(chat, index) in formattedChats.activeChats" :key="chat.id">
                  <v-list-item :value="chat">
                    <v-list-item-action class="ml-n1 mr-1">
                      <v-icon v-if="isNewChat(chat)" @click="removeChat(index)">mdi-close</v-icon>
                      <v-icon v-if="chat.hasUnreadMessages && chatList.isUserAHealthWorker" color="primary"
                        >mdi-circle-medium
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content
                      :class="{ 'font-weight-bold': chat.hasUnreadMessages && chatList.isUserAHealthWorker }"
                    >
                      <v-list-item-title v-text="chat.subject"></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="getFormattedParticipantFullNames(chat.participants)"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-if="chat.lastMessage"
                        class="mt-2"
                        v-text="getFullName(chat.lastSender) + ': ' + chat.lastMessage"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-else
                        class="mt-2"
                        v-text="$t('noPreviewAvailable')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text v-text="chat.formattedLastUpdate"></v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index < formattedChats.activeChats.length - 1"></v-divider>
                </div>
              </div>
              <div v-else>
                <p class="text--secondary ml-4">{{ $t('noChatHistory') }}</p>
              </div>

              <v-divider vertical></v-divider>

              <v-subheader class="text--secondary">
                {{ $t('archivedChats') }}
              </v-subheader>
              <div v-if="formattedChats.archivedChats.length > 0">
                <div v-for="(chat, index) in formattedChats.archivedChats" :key="chat.id">
                  <v-list-item :value="chat">
                    <v-list-item-action class="ml-n1 mr-1"> </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="chat.subject"></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="getFormattedParticipantFullNames(chat.participants)"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-if="chat.lastMessage"
                        class="mt-2"
                        v-text="getFullName(chat.lastSender) + ': ' + chat.lastMessage"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-else
                        class="mt-2"
                        v-text="$t('noPreviewAvailable')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text v-text="chat.formattedLastUpdate"></v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index < formattedChats.archivedChats.length - 1"></v-divider>
                </div>
              </div>
              <div v-else>
                <p class="text--secondary ml-4">{{ $t('noChatHistory') }}</p>
              </div>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-divider vertical class="no-print"></v-divider>
        <PatientMessageChatConversation
          ref="chatConversation"
          :selected-chat="selectedChat"
          :participant-full-names="selectedChatParticipantFullNames"
          :patient="patient"
          :is-health-worker-assigned-to-patient="chatList.isHealthWorkerAssignedToPatient"
          @selectedChatSubject="updatedSubject"
          @createNewChat="createNewChatId"
          @newMessageSent="shouldLoadChatList"
          @endChatWithPatient="shouldLoadChatList"
          @createVideoCall="createVideoCall"
        ></PatientMessageChatConversation>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import translation, { LanguageVue } from '@/translationMixin';
import { ConversationParticipantTypes, MonitoringTypes } from '@/components/PatientMonitoring/constants';
import accessibility from '@/accessibilityMixin';
import videoCallService from '@/services/videoCallService';
import PatientMessageChatConversation from './PatientMessageChatConversation.vue';
import { format } from 'date-fns';
import { frCA, enCA } from 'date-fns/locale';
import { isDateToday } from '@/utils/dateUtils';

export default {
  name: 'PatientMessageChatList',
  components: { PatientMessageChatConversation },

  mixins: [translationMixin, accessibility, translation],

  props: {
    chatList: {
      type: Object,
      required: true,
    },
    patient: {
      type: null,
      required: true,
    },
    availableParticipants: {
      type: Array,
      required: true,
    },
    selectedChatId: {
      type: Number,
      required: false,
      default: null,
    },
    isComponentActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      userLanguage: LanguageVue.getLanguage(),
      selectedChat: null,
      error: null,
      mustSelectChat: false,
    };
  },
  computed: {
    formattedChats() {
      if (!this.chatList || !this.chatList.conversations) {
        return { activeChats: [], archivedChats: [] };
      }
      this.chatList.conversations.forEach((chat) => {
        chat.formattedLastUpdate = this.getFormattedDate(chat.lastUpdate);
      });
      const activeChats = this.chatList.conversations.filter((chat) => !chat.completed);
      const archivedChats = this.chatList.conversations.filter((chat) => chat.completed);

      return { activeChats, archivedChats };
    },

    isChatAvailable() {
      return this.chatList.isHealthWorkerAssignedToPatient && this.chatList.isAssitantAssignedToPatient;
    },
    selectedChatParticipantFullNames() {
      return this.getFormattedParticipantFullNames(this.selectedChat?.participants);
    },
    availableCaregivers() {
      return this.availableParticipants.filter((x) => x.type === ConversationParticipantTypes.CAREGIVER);
    },
  },
  watch: {
    chatList() {
      if (this.mustSelectChat) {
        this.selectedChat = this.chatList.conversations[0];
        this.mustSelectChat = false;
      }
      if (this.selectedChatId) {
        this.selectedChat = this.chatList.conversations.find((x) => x.id === this.selectedChatId);
      }
    },
    selectedChat() {
      this.replacePath();
    },
    isComponentActive() {
      if (this.isComponentActive && this.selectedChat) {
        this.replacePath();
      }
    },
  },

  created: function () {
    LanguageVue.$on('projectLanguage', this.userLanguageUpdated);
  },

  beforeDestroy: function () {
    LanguageVue.$off('projectLanguage', this.userLanguageUpdated);
  },

  methods: {
    getFullName(participant) {
      if (!participant || !participant.firstName || !participant.lastName) {
        return '';
      }
      return `${participant.firstName} ${participant.lastName}`;
    },
    async createVideoCall(data) {
      let videoCallData = {
        patientId: this.patient.id,
        conversationId: data.conversationId,
      };

      try {
        let videoCall = await videoCallService.createVideoCall(videoCallData);
        this.$router.push({
          name: 'PatientVideoCall',
          params: { patientId: this.patient.id, callId: videoCall.id, conversationId: data.conversationId },
        });
      } catch (error) {
        this.error = error;
      }
    },

    isNewChat(chat) {
      return chat.id == null;
    },

    removeChat(index) {
      this.formattedChats.activeChats.splice(index, 1);
      this.selectedChat = null;
    },

    userLanguageUpdated(newLanguage) {
      this.userLanguage = newLanguage;
      this.chatList.conversations.forEach((activeChat) => {
        activeChat.formattedLastUpdate = this.getFormattedDate(activeChat.lastUpdate);
      });
    },

    addNewChat(selectedParticipant) {
      const participant = JSON.parse(JSON.stringify(selectedParticipant));
      if (!participant) {
        return;
      }

      const newDate = new Date();
      const participants = [participant];
      const newChat = {
        subject: '',
        lastMessage: '',
        lastUpdate: newDate,
        completed: false,
        hasUnreadMessages: false,
        formattedLastUpdate: this.getFormattedDate(newDate),
        participants,
      };

      this.formattedChats.activeChats.unshift(newChat);
      this.editingSubject = true;
      this.selectedChat = newChat;
    },

    updatedSubject(value) {
      this.selectedChat.subject = value;
    },

    shouldLoadChatList() {
      this.$emit('loadChatList');
    },

    createNewChatId(chatData) {
      this.shouldLoadChatList();
      if (!chatData.conversationId) {
        this.mustSelectChat = true;
      }

      this.selectedChat.lastMessage = chatData.content;
    },

    getFormattedDate(lastUpdate) {
      if (!lastUpdate) {
        return null;
      }
      const date = new Date(lastUpdate);
      const today = new Date();
      const options = {
        hour: 'numeric',
        minute: 'numeric',
      };

      if (this.userLanguage === 'en') {
        options.locale = enCA;
      } else {
        options.locale = frCA;
      }

      const currentYear = today.getFullYear();

      if (date.getFullYear() === currentYear) {
        if (isDateToday(lastUpdate)) {
          return format(date, 'p', options);
        } else {
          const formatString = this.userLanguage === 'en' ? 'MM-dd' : 'dd-MM';
          return format(date, formatString, options);
        }
      } else {
        const formatString = 'yyyy-MM-dd';
        return format(date, formatString, options);
      }
    },
    replacePath() {
      if (
        this.$route.params.detailType !== MonitoringTypes.CONVERSATIONS ||
        this.$route.params.detailId != this.selectedChat?.id
      ) {
        this.$router.replace({
          name: 'PatientMonitoringGrid',
          params: {
            detailType: MonitoringTypes.CONVERSATIONS,
            detailId: this.selectedChat?.id,
            patientId: this.$route.params.patientId,
          },
        });
      }
    },
    getFormattedParticipantFullNames(participants) {
      if (!participants || !participants.length) {
        return '';
      }

      const fullNames = [];
      const patient = participants.find((participant) => participant.type === ConversationParticipantTypes.PATIENT);
      if (patient) {
        fullNames.push(`${patient.firstName} ${patient.lastName}`);
      }
      const caregivers = participants.filter(
        (participant) => participant.type === ConversationParticipantTypes.CAREGIVER
      );
      caregivers.forEach((caregiver) => {
        fullNames.push(`${caregiver.firstName} ${caregiver.lastName}`);
      });

      return fullNames.join(', ');
    },
  },
};
</script>

<style scoped>
.chat-list-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: calc(90vh - 300px);
}
</style>
