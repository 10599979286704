<template>
  <div>
    <div v-for="(data, indexData) in showMoreAlerts ? patientData.alertsByDatetime : getFirstAlerts()" :key="indexData">
      <div class="text-body-2 font-italic mt-2">{{ getAlertDatetime(data.alertDatetime) }}</div>

      <div
        v-for="(alert, indexAlert) in data.alerts"
        :key="indexAlert"
        class="d-flex align-center mr-4"
        :class="
          [activityTypes.FDE, activityTypes.INC].includes(alert.activityType)
            ? 'width-165'
            : patientData.alertsByDatetime.some((x) => x.alerts.some((y) => y.activityType === activityTypes.APR))
            ? 'width-250'
            : patientData.alertsByDatetime.every((x) => x.alerts.every((y) => y.activityType === activityTypes.SAT))
            ? 'width-150'
            : 'width-210'
        "
      >
        <v-btn
          text
          block
          elevation="0"
          small
          class="no-text-transform pa-0 d-flex align-center justify-space-between"
          @click="viewFirstPatientMonitoringAlertDetail(patientData.patient.patientId, alert.activityId)"
        >
          <span class="text--secondary text-body-2">
            {{
              $t(
                `${
                  alert.activityType === activityTypes.FDE
                    ? 'Fall'
                    : alert.activityType === activityTypes.INC
                    ? 'Incontinence'
                    : alert.alert.monitoringValue
                }AlertRealtimeName`
              ) + (getLanguage() === 'fr' ? ' :' : ':')
            }}
          </span>

          <div class="d-flex align-center">
            <div v-if="alert.alert.type == 'variation'" class="text-body-1 text--primary font-weight-bold">
              {{ $t('patientMonitoringAlert.variation') }}
            </div>

            <div
              v-else-if="alert.activityType === activityTypes.FDE"
              class="text-body-1 text--primary font-weight-bold"
            >
              {{ $t('fallDetected') }}
            </div>

            <div
              v-else-if="alert.activityType === activityTypes.INC"
              class="text-body-1 text--primary font-weight-bold"
            >
              <div>
                {{
                  alert.alert.error
                    ? $t('error')
                    : alert.alert.status === statusLevels.WE
                    ? $t('Wet')
                    : alert.alert.status === statusLevels.CH
                    ? $t('Change')
                    : ''
                }}
              </div>
            </div>

            <template v-else>
              <div
                v-for="(value, valueIndex) in alert.alert.values"
                :key="valueIndex"
                class="text-body-1 text--primary"
              >
                <span
                  :class="
                    value.monitoringValue === alert.alert.monitoringValue ? ' text--primary font-weight-bold' : ''
                  "
                >
                  {{ value.value }}</span
                ><span>{{ valueIndex !== alert.alert.values.length - 1 ? '/' : '' }}</span>
              </div>

              <span v-if="![activityTypes.SAT, activityTypes.BDT].includes(alert.activityType)">&nbsp;</span
              ><span class="text-body-1 text--primary font-weight-bold">{{ $t(`${alert.activityType}Unit`) }}</span>
            </template>
          </div>
        </v-btn>

        <v-tooltip right class="p-6">
          <template #activator="{ on, attrs }">
            <v-icon class="ml-2" v-bind="attrs" small icon v-on="on"> mdi-information-outline </v-icon>
          </template>

          <v-row align="center">
            <v-col class="py-4">
              <span v-if="alert.activityType === activityTypes.FDE">
                {{ $t('patientMonitoringAlert.fallHasBeenDetected') }}
              </span>

              <template v-else-if="alert.activityType === activityTypes.INC">
                <span v-if="alert.alert.error">
                  {{ $t('error') + (getLanguage() === 'fr' ? ' ' : '') + $t(alert.alert.error) }}
                </span>

                <span v-else-if="alert.alert.status === statusLevels.WE">
                  {{ $t('patientMonitoringAlert.wetIncontinence') }}
                </span>

                <span v-else-if="alert.alert.status === statusLevels.CH">
                  {{ $t('patientMonitoringAlert.changeIncontinence') }}
                </span>
              </template>

              <span v-else>
                {{
                  $t('alertType') +
                  (getLanguage() === 'fr' ? ' ' : '') +
                  ': ' +
                  (alert.alert.type == 'variation'
                    ? $t('patientMonitoringAlert.variationThreshold')
                        .replace('{value}', alert.alert.threshold)
                        .replace('{unit}', $t(alert.alert.unitType))
                        .replace(
                          '{space}',
                          ['celsius', 'percentageVariation'].includes(alert.alert.unitType) ? '' : ' '
                        )
                        .replace('{daysValue}', alert.alert.length)
                    : $t(`${alert.alert.type}Threshold`).replace('{threshold}', alert.alert.threshold) +
                      ([activityTypes.SAT, activityTypes.BDT].includes(alert.activityType) ? '' : ' ') +
                      $t(alert.alert.unitType))
                }}</span
              >
            </v-col>
          </v-row>
        </v-tooltip>
      </div>
    </div>

    <v-btn
      v-if="patientData.alertsByDatetime.length >= 4"
      class="mt-2"
      x-small
      color="primary"
      @click="showMoreAlerts = !showMoreAlerts"
    >
      {{
        showMoreAlerts
          ? $t('patientMonitoringAlert.showLess')
          : $t('patientMonitoringAlert.showNextAlerts') +
            ` (+${
              patientData.alertsByDatetime.flatMap((x) => x.alerts).length -
              getFirstAlerts().flatMap((x) => x.alerts).length
            })`
      }}
    </v-btn>
  </div>
</template>

<script>
import { ActivityTypes, StatusLevels } from '@/components/PatientMonitoring/constants.js';
import translationMixin from '@/translationMixin';
import monitoringAlertRealtimeMixin from '@/components/PatientMonitoringAlertRealtime/monitoringAlertRealtimeMixin';

export default {
  name: 'MonitoringAlertRealtimeContentDetails',
  mixins: [monitoringAlertRealtimeMixin, translationMixin],
  props: {
    patientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activityTypes: ActivityTypes,
      statusLevels: StatusLevels,
      alertVisibleCount: 5,
      showMoreAlerts: false,
    };
  },

  methods: {
    getFirstAlerts: function () {
      const filteredObjects = this.patientData.alertsByDatetime.reduce((acc, obj) => {
        const totalLength = acc.reduce((sum, item) => sum + item.alerts.length, 0);
        return totalLength + obj.alerts.length <= this.alertVisibleCount ? [...acc, obj] : acc;
      }, []);

      return filteredObjects;
    },

    viewFirstPatientMonitoringAlertDetail: function (patientId, activityId) {
      // TO DO IN 1488: REDIRECT TO THE PATIENT VITAL SIGNS DETAILS
      this.$emit('viewMonitoringDetail', patientId, activityId);
    },
  },
};
</script>

<style scoped>
.width-250 {
  width: 250px;
}

.width-210 {
  width: 210px;
}

.width-150 {
  width: 150px;
}

.width-165 {
  width: 165px;
}

.no-text-transform {
  text-transform: none;
}
</style>
