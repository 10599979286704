<template>
  <div>
    <v-icon small :color="getStatusLevelColor(getWorstStatusLevelCode(patientData.alertsByDatetime))"
      >{{ getStatusLevelIcon(getWorstStatusLevelCode(patientData.alertsByDatetime)) }}
    </v-icon>

    <span class="text-body-1 text--primary font-weight-bold">
      {{ patientData.patient.lastName }}, {{ patientData.patient.firstName }}
    </span>

    <span v-if="patientData.patient.roombed.room" class="text--secondary text-body-2 ml-2">
      {{ $t('roombed') + (getLanguage() === 'fr' ? ' :' : ':') }} {{ patientData.patient.roombed.room }} -
      {{ patientData.patient.roombed.bed }}
    </span>

    <span class="mr-4 text-right" :class="{ grow: $vuetify.breakpoint.mdAndDown }">
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="resolvePatientMonitoringAlert(patientData.patient.patientId, patientData.alertsByDatetime)"
          >
            <v-list-item-icon><v-icon>mdi-check</v-icon></v-list-item-icon>
            <v-list-item-title>{{
              $t(
                patientData.alertsByDatetime.length > 0
                  ? 'patientMonitoringAlert.resolveAll'
                  : 'patientMonitoringAlert.resolved'
              )
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import monitoringAlertRealtimeMixin from '@/components/PatientMonitoringAlertRealtime/monitoringAlertRealtimeMixin';

export default {
  name: 'MonitoringAlertRealtimeContentHeader',
  mixins: [monitoringAlertRealtimeMixin, translationMixin],
  props: {
    patientData: {
      type: Object,
      required: true,
    },

    statusLevelsForActivityType: {
      // Props used in the monitoringAlertRealtimeMixin
      type: Array,
      required: true,
    },
  },

  methods: {
    viewPatientMonitoringDetail: function (patientId, activityId) {
      // TO DO IN 1488: REDIRECT TO THE PATIENT VITAL SIGNS DETAILS
      this.$emit('viewMonitoringDetail', patientId, activityId);
    },

    resolvePatientMonitoringAlert: function (patientId, patientMonitoringAlerts) {
      const monitoringAlertsIds = patientMonitoringAlerts.flatMap((x) =>
        x.alerts.flatMap((y) => y.patientMonitoringAlertId)
      );

      this.$emit('resolveAlert', patientId, monitoringAlertsIds);
    },
  },
};
</script>
