<template>
  <div
    class="d-flex"
    :class="{
      'mx-3': !isIotStateEvent() || $vuetify.breakpoint.mdAndDown,
      'mx-1': isIotStateEvent() && $vuetify.breakpoint.lgAndUp,
      'justify-space-between ': !isIotStateEvent() && $vuetify.breakpoint.lgAndDown,
      'pb-1': $vuetify.breakpoint.lgAndDown,
      'flex-column': $vuetify.breakpoint.xl,
      'max-width-460': $vuetify.breakpoint.mdAndDown && isIotStateEvent(),
      'min-width-100': $vuetify.breakpoint.xl && !isIotStateEvent(),
      'min-width-155': $vuetify.breakpoint.lgAndUp && isIotStateEvent(),
      'min-width-105':
        ($vuetify.breakpoint.lgAndUp && activityTypeCode === activityTypes.WEI) ||
        activityTypeCode === activityTypes.GLY,
      'max-width-300': $vuetify.breakpoint.smAndDown && !isIotStateEvent(),
      'width-300': $vuetify.breakpoint.lg && !isIotStateEvent(),
    }"
  >
    <div
      class="text--secondary text-body-2 d-flex justify-center align-center"
      :class="$vuetify.breakpoint.xl ? 'mb-1' : ''"
    >
      <IotRealtimeIcon :data-type-code="activityTypeCode" />
      <div class="pl-1">
        {{ $t(`${activityTypeCode}Name`)
        }}{{ $vuetify.breakpoint.lgAndDown ? (getLanguage() === 'fr' ? ' :' : ':') : '' }}
      </div>
    </div>

    <div
      v-if="data.patientActivityIotRealtime[activityTypeCode]"
      class="font-weight-bold text-body-1 d-flex"
      :class="{
        'width-100': $vuetify.breakpoint.lgAndDown && isIotStateEvent(),
        'justify-center':
          ![activityTypes.WEI, activityTypes.GLY, activityTypes.FDE, activityTypes.INC].includes(activityTypeCode) ||
          $vuetify.breakpoint.xl,
        'ml-1': !$vuetify.breakpoint.xl,
      }"
    >
      <template
        v-if="
          hasValues() &&
          ([activityTypes.WEI, activityTypes.GLY].includes(activityTypeCode) ||
            realtimeVitalSignsCodes.includes(activityTypeCode))
        "
      >
        <IotRealtimeGridVitalSigns :activity-type-code="activityTypeCode" :patient-vital-sign="data" />
      </template>

      <template v-else-if="isIotStateEvent() && hasValues()">
        <IotRealtimeGridIotStates :iot-states-event="data.patientActivityIotRealtime[activityTypeCode]" />
      </template>

      <div v-else class="font-weight-medium text-body-2 text-center">{{ $t('iotRealtime.noData') }}</div>
    </div>

    <div v-else class="d-flex justify-center">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon v-on="on">mdi-minus-thick</v-icon>
        </template>

        <span>{{ $t('iotRealtime.noMonitoring') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';

import IotRealtimeIcon from '@/components/PatientIotRealtime/RealtimeIcons/IotRealtimeIcon.vue';

import { ActivityTypes } from '@/components/PatientMonitoring/constants.js';
import { RealtimeVitalSignsCodes } from '@/components/PatientIotRealtime/constants.js';

import IotRealtimeGridIotStates from './IotRealtimeGridIotStates.vue';
import IotRealtimeGridVitalSigns from './IotRealtimeGridVitalSigns.vue';

export default {
  name: 'IotRealtimeGridData',

  components: { IotRealtimeGridIotStates, IotRealtimeGridVitalSigns, IotRealtimeIcon },
  mixins: [translationMixin],

  props: {
    activityTypeCode: {
      required: true,
      type: String,
    },

    data: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      activityTypes: ActivityTypes,
      realtimeVitalSignsCodes: RealtimeVitalSignsCodes,
    };
  },

  methods: {
    hasValues: function () {
      return this.data.patientActivityIotRealtime[this.activityTypeCode]?.values;
    },

    isIotStateEvent: function () {
      return [ActivityTypes.WEI, ActivityTypes.FDE, ActivityTypes.INC, ActivityTypes.GLY].includes(
        this.activityTypeCode
      );
    },
  },
};
</script>

<style scoped>
.min-width-155 {
  min-width: 155px;
}

.min-width-105 {
  min-width: 105px;
}

.min-width-100 {
  min-width: 100px;
}

.max-width-460 {
  max-width: 460px;
}

.max-width-300 {
  max-width: 300px;
}

.width-300 {
  width: 290px;
}

.width-100 {
  width: 100%;
}
</style>
