<template>
  <v-badge
    ref="badge"
    :value="unreadCount > 0"
    :offset-y="$vuetify.breakpoint.xs ? -2 : 10"
    overlap
    :content="unreadCount"
  />
</template>

<script>
import notificationService from '@/services/notificationService';
import { NotificationRefreshIntervals } from './constants';

export default {
  name: 'NotificationCount',

  data() {
    return {
      unreadCount: 0,
      timer: null,
    };
  },

  async mounted() {
    this.startTimer();
  },

  beforeDestroy() {
    this.stopTimer();
  },

  methods: {
    async refreshUnreadNotificationCount() {
      try {
        let data = await notificationService.getUnreadNotificationCount();
        this.unreadCount = data.unreadCount;
      } catch (error) {
        this.error = error;
        this.unreadCount = 0;
      }
    },

    startTimer() {
      this.refreshUnreadNotificationCount();
      this.timer = setInterval(this.refreshUnreadNotificationCount, NotificationRefreshIntervals.DEFAULT);
    },

    stopTimer() {
      clearInterval(this.timer);
    },

    resetTimer() {
      this.stopTimer();
      this.startTimer();
    },
  },
};
</script>
