import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#dc5f26',
      },
    },
    options: { cspNonce: 'EEAD79A6F622816D5160D0787C86A804' },
  },
});
