<template>
  <div>
    <v-row class="my-3">
      <v-col>
        <v-text-field
          v-model="searchInput"
          dense
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          clearable
          outlined
          @input="filterPatientOnSeachValue"
        >
        </v-text-field>
      </v-col>

      <v-col cols="auto">
        <v-btn ref="filtersIcon" icon @click="showFilterSheet = !showFilterSheet">
          <v-icon v-if="selectedCohortIds.length === 0">mdi-filter</v-icon>
          <v-icon v-else color="primary">mdi-filter-check</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="filteredIotRealtime.length === 0">
      {{ $t('iotRealtime.noPatientToMonitor') }}
    </div>

    <template v-else>
      <IotRealtimeGridContent
        ref="iotRealtimeGridContent"
        :activity-types-codes="activityTypesCodes"
        :filtered-iot-realtime="filteredIotRealtime"
        @viewPatientIotRealtimeDetail="viewPatientIotRealtimeDetail"
      />
    </template>

    <v-navigation-drawer v-model="showFilterSheet" fixed temporary right touchless>
      <v-card flat>
        <v-card-title>{{ $t('filters') }}</v-card-title>

        <v-card-text>
          <v-row align="start">
            <v-col align-self="start" :cols="12">
              <v-select
                :id="getIdByName('cohortMonitoring')"
                ref="cohortMonitoring"
                v-model="selectedCohortIds"
                clearable
                multiple
                :label="$t('cohortMonitoring')"
                :items="cohorts"
                item-text="name"
                item-value="id"
                outlined
                small-chips
                deletable-chips
                :no-data-text="$t('noDataAvailable')"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="12">
              <div class="d-flex flex-row">
                <v-btn ref="apply" color="primary" class="mt-2 ml-auto" @click.stop="emitCohortFilter()">
                  <v-icon> mdi-filter </v-icon>
                  {{ $t('apply') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import accessibility from '@/accessibilityMixin';
import translationMixin from '@/translationMixin';
import { containsString } from '@/utils/stringUtils';

import IotRealtimeGridContent from './IotRealtimeGridContent.vue';

let selectedCohortIds = []; // Global variables so that when the user navigates through the application, their values don't change
let searchValue = '';

export default {
  name: 'IotRealtimeGridContainer',

  components: {
    IotRealtimeGridContent,
  },

  mixins: [accessibility, translationMixin],

  props: {
    activityTypesCodes: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },

    iotRealTimeData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },

    cohorts: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      filteredIotRealtime: [],
      selectedCohortIds: selectedCohortIds,
      searchInput: searchValue,
      showFilterSheet: false,
    };
  },

  watch: {
    iotRealTimeData: function () {
      this.filterPatientOnSeachValue();
    },

    searchInput(newVal) {
      searchValue = newVal;
    },
  },

  created() {
    this.filterPatientOnSeachValue();
  },

  methods: {
    filterPatientOnSeachValue: function () {
      if (!this.searchInput) {
        this.filteredIotRealtime = this.iotRealTimeData;
        return;
      }

      this.filteredIotRealtime = this.iotRealTimeData.filter((iotRealTime) => {
        const { firstName, lastName } = iotRealTime.patient;

        return (
          containsString(` ${lastName} ${firstName}`, this.searchInput) ||
          containsString(` ${firstName} ${lastName}`, this.searchInput)
        );
      });
    },

    emitCohortFilter: function () {
      selectedCohortIds = this.selectedCohortIds;
      this.$emit('applyCohortFilter', this.selectedCohortIds);
    },

    viewPatientIotRealtimeDetail: function (patientId) {
      this.$emit('viewPatientIotRealtimeDetail', patientId);
    },
  },
};
</script>
