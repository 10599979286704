export default {
  en: {
    'patientMonitoringAlert.title': 'Alerts Monitoring',
    'patientMonitoringAlert.fall': 'Fall',
    'patientMonitoringAlert.noInformation': 'No information to display',
    'patientMonitoringAlert.change': 'Consider change',
    'patientMonitoringAlert.error': 'Error',
    'patientMonitoringAlert.we': 'Some urine',
    'patientMonitoringAlert.dr': 'OK',
    'patientMonitoringAlert.alert': 'Alert',
    'patientMonitoringAlert.resolved': 'Resolved',
    'patientMonitoringAlert.resolveAll': 'Resolve All',
    'patientMonitoringAlert.firstAlert': 'First alert details',
    'patientMonitoringAlert.variation': 'Variation',

    'patientMonitoringAlert.variationThreshold':
      'Variation equivalent to {value} {unit} of the usual value for the last {daysValue} days',

    'patientMonitoringAlert.showLess': 'Show less',
    'patientMonitoringAlert.showNextAlerts': 'Show next alerts',
    'patientMonitoringAlert.fallHasBeenDetected': 'A fall has been detected',
    'patientMonitoringAlert.wetIncontinence': 'The incontinence brief is wet',
    'patientMonitoringAlert.changeIncontinence': 'The incontinence brief must be changed',

    vitalSigns: 'Vital Signs',
    capillaryGlycemia: 'Capillary Glycemia',

    percentageVariation: '%',
    percent: '%',
    breathingCycles: 'breathing cycles',
    celsius: '°C',
    mmhg: 'mmhg',
    bpm: 'bpm',

    SATAlertRealtimeName: 'Saturation',
    breathingCyclesAlertRealtimeName: 'Respiratory Rate',
    BeatsPerMinAlertRealtimeName: 'Heart Rate',
    DAPRAlertRealtimeName: 'Diastolic pressure',
    SAPRAlertRealtimeName: 'Systolic pressure',
    BodyTemperatureAlertRealtimeName: 'Body Temperature',
    BreathingAlertRealtimeName: 'Respiratory Rate',
    CapillaryGlycemiaAlertRealtimeName: 'Capillary Glycemia',
    FallAlertRealtimeName: 'Fall',
    IncontinenceAlertRealtimeName: 'Incontinence',

    biggerThanThreshold: 'Threshold greater than {threshold}',
    lesserThanThreshold: 'Threshold less than {threshold}',
  },
  fr: {
    'patientMonitoringAlert.title': 'Surveillance alertes',
    'patientMonitoringAlert.fall': 'Chute',
    'patientMonitoringAlert.noInformation': 'Aucune information à afficher',
    'patientMonitoringAlert.change': 'Changement',
    'patientMonitoringAlert.error': 'Erreur',
    'patientMonitoringAlert.we': "Présence d'urine",
    'patientMonitoringAlert.dr': 'OK',
    'patientMonitoringAlert.alert': 'Alerte',
    'patientMonitoringAlert.resolved': 'Résolue',
    'patientMonitoringAlert.resolveAll': 'Tout résoudre',
    'patientMonitoringAlert.firstAlert': 'Détails première alerte',
    'patientMonitoringAlert.variation': 'Variation',

    'patientMonitoringAlert.variationThreshold':
      'Variation équivalente à {value}{space}{unit} de la valeur usuelle des {daysValue} derniers jours',

    'patientMonitoringAlert.showLess': 'Afficher moins',
    'patientMonitoringAlert.showNextAlerts': 'Afficher alertes suivantes',
    'patientMonitoringAlert.fallHasBeenDetected': 'Une chute a été detectée',
    'patientMonitoringAlert.wetIncontinence': "La culotte d'incontinence est humide",
    'patientMonitoringAlert.changeIncontinence': "La culotte d'incontinence doit être changée",

    vitalSigns: 'Signes vitaux',
    capillaryGlycemia: 'Glycémie capillaire',

    percentageVariation: '%',
    percent: '%',
    breathingCycles: 'cycles de respiration',
    celsius: '°C',
    mmhg: 'mmhg',
    bpm: 'bpm',
    mmolperl: 'mmol/l',

    SATAlertRealtimeName: 'Saturation',
    breathingCyclesAlertRealtimeName: 'Fréq. respiratoire',
    BeatsPerMinAlertRealtimeName: 'Fréq. cardiaque',
    DAPRAlertRealtimeName: 'Press. diastolique',
    SAPRAlertRealtimeName: 'Press. systolique',
    BodyTemperatureAlertRealtimeName: 'Température corpo.',
    BreathingAlertRealtimeName: 'Fréq. respiratoire',
    CapillaryGlycemiaAlertRealtimeName: 'Glycémie capillaire',
    FallAlertRealtimeName: 'Chute',
    IncontinenceAlertRealtimeName: 'Incontinence',

    biggerThanThreshold: 'Seuil supérieur à {threshold}',
    lesserThanThreshold: 'Seuil inférieur à {threshold}',
  },
};
