export default {
  en: {
    alertNotificationTriggered: 'An alert has been triggered for the activity',
    alertNotificationsDeleted: 'Notifications were successfully deleted',
    notificationAlertType: 'Alert',
    notificationNewMessageType: 'New message from',
    newMessage: 'You have received a new message in the conversation',
    notificationDialog: 'Dialog:',
    notificationCallBackRequest: 'Patient Callback request',
    newCallBackRequest: 'A callback request has been received from',
    alertTypeData: 'Alert Type:',
    openNotification: 'View',
    patientRoomAlert: 'Room',
    notification_redirecting: 'Redirecting to notification details',
  },
  fr: {
    alertNotificationTriggered: "Une alerte a été déclenchée pour l'activité",
    alertNotificationsDeleted: 'Les notifications ont été supprimées avec succès',
    notificationAlertType: 'Alerte',
    notificationNewMessageType: 'Nouveau message de',
    newMessage: 'Vous avez reçu un nouveau message dans la conversation',
    notificationDialog: 'Dialogue:',
    notificationCallBackRequest: 'Demande de rappel patient',
    newCallBackRequest: 'Une demande de rappel a été reçue de la part de',
    alertTypeData: "Type d'alerte:",
    openNotification: 'Consulter',
    patientRoomAlert: 'Chambre',
    notification_redirecting: 'Redirection vers le détail de la notification',
  },
};
