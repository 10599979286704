<template>
  <v-row align="center">
    <v-col cols="auto mr-3">
      <h3>{{ $t(title) }}</h3>
    </v-col>

    <v-col
      v-for="(activityType, index) in vitalSignsAlertCount"
      :key="index"
      cols="auto"
      class="px-2 d-flex align-center"
    >
      <img
        v-if="
          [activityTypes.APR, activityTypes.BDT, activityTypes.CFR, activityTypes.RES, activityTypes.SAT].includes(
            activityType.code
          )
        "
        :src="getIconOnDataTypeCode(activityType)"
        :alt="activityType.code + '-icon'"
        height="24px"
      />
      <img
        v-else-if="activityType.statusLevelCode === statusLevels.CH"
        :src="getStatusChangeIconColor(activityType.alertCount)"
        class="mr-1"
        alt="change-incontinence-icon"
      />

      <v-icon v-else medium :color="getStatusLevelColorByCount(activityType, activityType.alertCount)">
        {{ activityType.icon }}
      </v-icon>

      <span ref="statusLevelCount"> {{ activityType.alertCount }} </span>
    </v-col>
  </v-row>
</template>

<script>
import { ActivityTypes, StatusLevels } from '@/components/PatientMonitoring/constants';
import monitoringAlertRealtimeMixin from '@/components/PatientMonitoringAlertRealtime/monitoringAlertRealtimeMixin';
import translationMixin from '@/translationMixin';

export default {
  name: 'PatientMonitoringAlertHeaderRealtime',

  mixins: [monitoringAlertRealtimeMixin, translationMixin],
  props: {
    vitalSignsAlertCount: {
      type: Array,
      default: () => [],
      required: false,
    },

    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      activityTypes: ActivityTypes,
      changeEssity: '/static/incontinenceicon/change_essity.svg',
      changeGreyEssity: '/static/incontinenceicon/change_essity_grey.svg',
      healthIconsSource: '/static/healthIcons/',
      statusLevels: StatusLevels,
      vitalSignsIcons: {
        [ActivityTypes.APR]: 'blood_pressure.svg',
        [ActivityTypes.BDT]: 'thermometer.svg',
        [ActivityTypes.CFR]: 'heart.svg',
        [ActivityTypes.RES]: 'lungs.svg',
        [ActivityTypes.SAT]: 'saturation.svg',
      },
    };
  },

  methods: {
    getIconOnDataTypeCode: function (activityType) {
      const iconColor = activityType.alertCount > 0 ? 'filled_red_' : '';
      return this.healthIconsSource + iconColor + this.vitalSignsIcons?.[activityType.code];
    },
  },
};
</script>
