<template>
  <div class="print-fit">
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />

    <v-row align="center" class="search-filter">
      <v-col>
        <v-text-field
          v-model="search"
          dense
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          clearable
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-menu ref="settingsMenu" offset-y @input="onMenuToggle">
          <template #activator="{ on, attrs }">
            <v-btn ref="configButton" icon v-bind="attrs" v-on="on">
              <v-icon> mdi-cog </v-icon>
            </v-btn>
          </template>

          <v-list dense @click.stop>
            <div class="field-list-container">
              <draggable v-model="allColumns" group="people" draggable=".item" handle=".handle" @end="dragColumnEnd">
                <v-list-item v-for="item in allColumns" :key="item.value" class="item">
                  <v-list-item-content @click.stop>
                    <div>
                      <v-icon class="handle mr-2">mdi-drag-horizontal-variant</v-icon>
                      <v-icon
                        v-if="item.visible"
                        ref="columnVisibility"
                        class="mx-2"
                        color="primary"
                        @click="toggleColumnVisibility(item)"
                      >
                        mdi-eye-outline
                      </v-icon>
                      <v-icon v-else ref="columnVisibility" class="mx-2" @click="toggleColumnVisibility(item)"
                        >mdi-eye-off-outline</v-icon
                      >
                      <span>{{ $t(item.text) }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </draggable>
            </div>
            <v-list-item class="mt-2">
              <v-list-item-title>
                <v-btn depressed @click.stop="resetColumns">
                  <v-icon left> mdi-eye-refresh-outline </v-icon>
                  {{ $t('reset') }}
                </v-btn>
                <v-btn class="ml-2" depressed @click.stop="clearFilters()">
                  <v-icon left> mdi-filter-remove-outline </v-icon>
                  {{ $t('clearFilters') }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="2" :class="headers.length > maxColumns ? 'no-print' : []">
          <v-data-table
            ref="dataTable"
            v-model="selected"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :headers="headers"
            :items="filteredItems"
            :search="search"
            mobile-breakpoint="0"
            :show-select="showSelect"
            :single-select="singleSelect"
            :item-key="itemKey"
            :footer-props="footerProps"
            :items-per-page="itemsPerPage"
            :custom-filter="filterFunc"
            :no-data-text="dataLoading ? '' : $t('noDataInTable')"
            :height="!!tableHeight ? `${tableHeight}` : undefined"
            :fixed-header="!!tableHeight"
            :show-expand="showExpand"
            @click:row="rowClick"
            @item-expanded="itemExpanded"
          >
            <template v-for="h in headers" #[`header.${h.value}`]="{ header }">
              <span :key="h.value" class="datagrid-header" :class="h.value === 'actions' ? ['no-print'] : []">
                <span>{{ $t(header.text) }}</span>
                <v-menu ref="menuFilters" v-model="filters[h.value].visible" offset-y>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <template v-if="h.filterable">
                        <v-icon
                          v-if="filters[h.value].filtered"
                          ref="showFilters"
                          class="mx-2 datagrid-header__filter-icon datagrid-header__filter-icon--active"
                          small
                          color="primary"
                        >
                          mdi-filter-check
                        </v-icon>
                        <v-icon v-else ref="showFilters" class="mx-2 datagrid-header__filter-icon" small>
                          mdi-filter
                        </v-icon>
                      </template>
                    </span>
                  </template>
                  <div>
                    <data-grid-column-filter
                      v-if="filters[h.value].visible"
                      :property-name="h.value"
                      :selected-values="filters[h.value].values"
                      :items="items"
                      @cancel="filters[h.value].visible = false"
                      @update-filters="(e) => updateFilters(h.value, e)"
                    >
                    </data-grid-column-filter>
                  </div>
                </v-menu>
              </span>
            </template>

            <template v-for="h in headersWithItemSlot" #[`item.${h.value}`]="props">
              <slot v-bind="props" :name="`item.${h.value}`"></slot>
            </template>

            <template #expanded-item="{ item }">
              <slot :item="item" name="expanded-item"></slot>
            </template>

            <template #item.data-table-select="{ item, isSelected, select }">
              <v-simple-checkbox v-if="!item.disabled" :value="isSelected" @input="select($event)"></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-sheet>

        <v-sheet elevation="2" :class="headers.length > maxColumns ? 'd-none d-print-block' : 'd-none'">
          <v-data-table
            v-for="(headerChunk, index) in headerChunks"
            :ref="`dataTable_${index}`"
            :key="index"
            v-model="selected"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :headers="headerChunk"
            :items="filteredItems"
            :search="search"
            mobile-breakpoint="0"
            :show-select="showSelect"
            :single-select="singleSelect"
            :item-key="itemKey"
            :footer-props="footerProps"
            :items-per-page="-1"
            :custom-filter="filterFunc"
            :height="!!tableHeight ? `${tableHeight}` : undefined"
            :fixed-header="!!tableHeight"
            :show-expand="showExpand"
            @click:row="rowClick"
            @item-expanded="itemExpanded"
          >
            <template v-for="h in headerChunk" #[`header.${h.value}`]="{ header }">
              <span :key="h.value" class="datagrid-header">
                <span>{{ $t(header.text) }}</span>
              </span>
            </template>

            <template v-for="h in headersWithItemSlot" #[`item.${h.value}`]="props">
              <slot v-bind="props" :name="`item.${h.value}`"></slot>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import translation from '@/translationMixin';
import DataGridColumnFilterVue from './DataGridColumnFilter.vue';
import virtuoseMixin from '@/virtuoseMixin';
import { containsString } from '@/utils/stringUtils';

export default {
  components: {
    draggable,
    DataGridColumnFilter: DataGridColumnFilterVue,
  },
  mixins: [translation, virtuoseMixin],
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: undefined,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    gridName: {
      type: String,
      required: false,
      default: null,
    },
    tableHeight: {
      type: String,
      required: false,
      default: undefined,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    dataLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      sortBy: '',
      sortDesc: false,
      headers: [],
      allColumns: [],
      filters: {},
      filteredItems: [],
      selected: [],
      configurations: null,
      showWaitModal: false,
      error: null,
      maxColumns: 12,
    };
  },

  computed: {
    headersWithItemSlot() {
      return this.headers.filter((h) => this.hasSlot(`item.${h.value}`));
    },

    headerChunks() {
      const chunkSize = this.maxColumns;
      const chunks = [];
      const headers = this.headers.filter((h) => h.value !== 'newMessageCount' && h.value !== 'actions');
      const lastNameHeader = headers.find((h) => h.value === 'lastName');
      const firstNameHeader = headers.find((h) => h.value === 'firstName');

      for (let i = 0; i < headers.length; i += chunkSize) {
        let chunk = headers.slice(i, i + chunkSize);

        if (
          !chunk.some((h) => h.value === 'firstName' || h.value === 'lastName') &&
          lastNameHeader &&
          firstNameHeader
        ) {
          chunk = [lastNameHeader, firstNameHeader, ...chunk.slice(0, chunkSize - 2)];
        }

        chunks.push(chunk);
      }

      return chunks;
    },

    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },
  },
  watch: {
    columns: {
      handler: function () {
        this.init();
      },
    },
    items: {
      handler: function () {
        this.updateFilteredItems();
      },
    },
    sortBy() {
      this.configurationChanged();
    },
    sortDesc() {
      this.configurationChanged();
    },
    selectedItems: {
      handler(newValue) {
        this.selected = newValue;
      },
    },
    selected(newValue) {
      this.$emit('update:selectedItems', newValue);

      // If there's disabled items, we must exclude them from selection

      if (newValue) {
        let newSelection = newValue.filter((item) => !item.disabled);
        if (newSelection.length !== newValue.length) {
          this.selected = newSelection;
        }
      }
    },
    configurations(newValue) {
      this.emitConfigurationChanged(newValue ?? this.getCurrentConfiguration());
    },
  },

  created: function () {
    this.init();
  },

  beforeDestroy: function () {
    if (this.$refs.settingsMenu && this.$refs.settingsMenu.isActive) {
      this.updateGridConfig();
    }
  },

  methods: {
    init: function () {
      this.initColumns();
      this.updateFilteredItems();
      this.emitConfigurationChanged(this.getCurrentConfiguration());
      this.getGridConfig();
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    hasSlot(slotName) {
      return !!this.$scopedSlots[slotName];
    },
    updateHeaders() {
      this.headers = this.allColumns
        .filter((c) => c.visible)
        .map((c) => {
          return {
            text: c.text,
            value: c.value,
            // divider: true,
            width: c.width,
            filterable: c.filterable,
            sortable: c.sortable,
            align: c.align,
          };
        });

      let filters = {};
      this.headers.forEach((h) => {
        filters[h.value] = this.filters[h.value] || {
          visible: false,
          filtered: false,
          values: [],
        };
      });
      this.filters = filters;
    },
    updateFilteredItems() {
      let filtered = this.items ?? [];

      for (let name in this.filters) {
        if (Object.prototype.hasOwnProperty.call(this.filters, name) && this.filters[name].filtered) {
          filtered = filtered.filter((x) => this.filters[name].values.includes(x[name]));
        }
      }
      this.filteredItems = filtered;
    },
    dragColumnEnd() {
      this.updateHeaders();
      this.configurationChanged();
    },
    toggleColumnVisibility(column) {
      column.visible = !column.visible;
      this.updateHeaders();
      this.updateFilteredItems();
      this.configurationChanged();
    },
    updateFilters(name, values) {
      this.filters[name].values = values || [];
      this.filters[name].filtered = !!values;
      this.updateFilteredItems();
      this.configurationChanged();
    },
    clearFilters() {
      this.allColumns.forEach((c) => {
        if (this.filters[c.value]) {
          this.filters[c.value].filtered = false;
          this.filters[c.value].values = [];
        }
      });
      this.updateFilteredItems();
      this.configurationChanged();
    },
    resetColumns() {
      this.initColumns();
      this.configurationChanged();
    },
    initColumns() {
      this.allColumns = (this.columns ?? []).map((column) => {
        return {
          text: column.text,
          value: column.value,
          visible: column.visible === undefined || !!column.visible,
          filterable: column.filterable === undefined || !!column.filterable,
          sortable: column.sortable === undefined || !!column.sortable,
          filtered: false,
          align: column.align,
          width: column.width,
        };
      });
      this.updateHeaders();
    },
    getCurrentConfiguration() {
      let visibility = this.allColumns.map((x) => {
        return {
          key: x.value,
          visible: x.visible,
        };
      });

      let visibilityIsSameAsOrignal =
        visibility.length === this.columns.length &&
        visibility.every(
          (item, idx) => this.columns[idx].value === item.key && !!(this.columns[idx].visible ?? true) === item.visible
        );
      if (visibilityIsSameAsOrignal) {
        // If the order / visibility status is the same as the default one, we don't save it in the config
        visibility = [];
      }

      return {
        sortBy: this.sortBy,
        sortAscending: !this.sortDesc,
        visibility: visibility,
        filters: this.allColumns
          .filter((x) => this.filters[x.value] && this.filters[x.value].filtered)
          .map((x) => {
            return {
              key: x.value,
              values: this.filters[x.value].values,
            };
          }),
      };
    },
    configurationChanged() {
      this.configurations = this.getCurrentConfiguration();
    },

    setCurrentConfiguration: function () {
      if (this.configurations) {
        this.configurations.visibility = this.configurations.visibility.filter((column) =>
          this.columns.some((c) => c.value === column.key)
        );
        this.sortDesc = this.configurations.sortAscending === false;
        this.sortBy = this.configurations.sortBy;

        const orderedColumns = [];
        const existingColumns = this.configurations.visibility.map((column) => column.key);

        for (const column of this.configurations.visibility) {
          const matchingColumn = this.allColumns.find((c) => c.value === column.key);

          if (matchingColumn) {
            matchingColumn.visible = column.visible;
            orderedColumns.push(matchingColumn);
          }
        }

        const newColumns = this.allColumns.filter((column) => !existingColumns.includes(column.value));

        if (newColumns.length > 0) {
          // Will insert new activity column before communication column in PatientMonitoringGrid
          const insertIndex = orderedColumns.length - 1;
          orderedColumns.splice(insertIndex, 0, ...newColumns);
        }

        this.allColumns = orderedColumns;
      }
    },

    filterFunc(value, search) {
      return containsString(value, search);
    },

    getGridConfig: function () {
      var success = function (response) {
        this.showWaitModal = false;
        this.configurations = response.data.configuration;
        this.setCurrentConfiguration();
        this.updateHeaders();
      };

      if (this.gridName != null) {
        this.showWaitModal = true;
        this.getUserGridConfiguration(this.gridName, success, this.defaultErrorCallBack);
      }
    },

    updateGridConfig: function () {
      var success = function () {
        this.showWaitModal = false;
      };

      if (this.gridName != null && this.configurations != null) {
        this.showWaitModal = true;
        this.updateUserGridConfiguration(this.gridName, this.configurations, success, this.defaultErrorCallBack);
      }
    },

    onMenuToggle: function (opened) {
      if (opened == false) {
        this.updateGridConfig();
      }
    },
    rowClick(item) {
      this.$emit('row:click', item);
    },
    itemExpanded(data) {
      this.$emit('item-expanded', data);
    },
    emitConfigurationChanged(configuration) {
      let newConfig = { ...configuration };
      // if there's not visibility, it means it's the default values, so we're goin to pass them here
      if (newConfig.visibility.length === 0) {
        newConfig.visibility = this.allColumns.map((x) => {
          return {
            key: x.value,
            visible: x.visible,
          };
        });
      }
      this.$emit('configuration-changed', newConfig);
    },
  },
};
</script>

<style lang="scss" scoped>
.datagrid-header__filter-icon {
  opacity: 0.3;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
}

.datagrid-header__filter-icon--active {
  opacity: 1;
}

th:hover .datagrid-header__filter-icon {
  opacity: 1;
}

.handle {
  cursor: move;
}

.field-list-container {
  max-height: min(500px, 100vh - 100px);
  overflow-y: auto;
}
</style>
