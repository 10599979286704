<template>
  <v-container>
    <!-- <ConfirmationModal
      :question="$t('deleteProfessionalQuestion')"
      :show="showDelete"
      :title="$t('delete')"
      @cancel="showDelete = false"
      @ok="deleteSelectedProfessional"
    /> -->

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('healthworkers') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.healthWorker)">
      <v-col>
        <div>
          <v-btn ref="addProfessional" color="primary" @click="editProfessionalAction(null)">
            {{ $t('add') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />

    <v-row>
      <v-col>
        <DataGrid
          :items="professionals"
          :columns="columns"
          grid-name="healthcare_professional_grid"
          :show-select="false"
          :items-per-page="25"
          :data-loading="showWaitModal"
        >
          <!-- <template #item.active="{ item }">
            <v-simple-checkbox v-model="item.active" disabled />
          </template> -->

          <template #item.actions="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can(permissions.actions.change, permissions.subjects.healthWorker)"
                  :ref="`editProfessional_${item.id}`"
                  @click="editProfessionalAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <!-- <v-divider
                  v-if="
                    $can(permissions.actions.change, permissions.subjects.healthWorker) &&
                    $can(permissions.actions.delete, permissions.subjects.healthWorker)
                  "
                ></v-divider>

                <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.healthWorker)"
                  :ref="`deleteProfessional_${item.id}`"
                  @click="deleteAction(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>
    <HealthCareProfessionalModal
      :show.sync="showEditModal"
      :professional-id="selectedProfessionalId"
      @refresh="init()"
    />
  </v-container>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import HealthCareProfessionalModal from './HealthCareProfessionalModal';
import translationMixin from '@/translationMixin';
import healthcareProfessionalService from '@/services/healthcareProfessionalService';
import permissionMixin from '@/auth/permissionMixin';

export default {
  name: 'HealthcareProfessionalGrid',
  components: {
    DataGrid,
    HealthCareProfessionalModal,
  },
  mixins: [translationMixin, permissionMixin],
  props: {},
  data() {
    return {
      showDelete: false,
      showEditModal: false,
      error: null,
      selectedProfessionalId: null,
      showWaitModal: false,

      professionals: [],
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'employeeNumber',
          value: 'employeeNumber',
          filterable: true,
        },
        {
          text: 'lastName',
          value: 'lastName',
          filterable: true,
        },
        {
          text: 'firstName',
          value: 'firstName',
          filterable: true,
        },
        {
          text: 'type',
          value: 'type',
          filterable: true,
        },
        // {
        //   text: 'active',
        //   value: 'active',
        //   filterable: true,
        // },
        {
          text: 'createdDate',
          value: 'createdAt',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdBy',
          value: 'createdBy.username',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedDate',
          value: 'updatedAt',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedBy',
          value: 'updatedBy.username',
          filterable: true,
          visible: false,
        },
      ];
      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.healthWorker)
        // || this.$can(this.permissions.actions.delete, this.permissions.subjects.healthWorker)
      ) {
        columns.push({
          text: 'actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: async function () {
      this.professionals = [];
      this.showWaitModal = true;
      this.showDelete = false;
      this.showEditModal = false;

      try {
        let data = await healthcareProfessionalService.getHealthcareProfessionals();
        this.professionals = data.map((item) => {
          var result = item;
          item.type = item.healthworkerType?.name;
          return result;
        });
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    editProfessionalAction: function (id) {
      this.selectedProfessionalId = id;
      this.showEditModal = true;
    },

    // deleteAction: function (id) {
    //   this.selectedProfessionalId = id;
    //   this.showDelete = true;
    // },

    // async deleteSelectedProfessional() {
    //   this.showDelete = false;
    //   this.showWaitModal = true;
    //   try {
    //     await healthcareProfessionalService.deleteHealthcareProfessional(this.selectedProfessionalId);
    //     this.init();
    //   } catch (error) {
    //     this.error = error;
    //   }
    //   this.showWaitModal = false;
    // },
  },
};
</script>

<style scoped></style>
